.header-greetings {
	padding: 0;
	@include header;
	background: transparent;
	height: 150px;
	width: 100%;
	margin-bottom: 20px;
	transition: 0.5s all;
	.logo-wrap {
		margin: 22px;
		padding: 20px;
		background: rgba(0, 0, 0, 0.3);
		position: fixed;
		border-radius: 25px;
		animation: totransparent 0.25s forwards;
		width: calc(100% - 84px);
		z-index: 1000;
		max-height: 22px !important;
		transition: 0.5s all;

		@media (min-width: 544px) {
			max-width: $desktop-width;
			width: 100%;
			margin: 0px;
			padding: 0px;
		}
		img, svg {
			/*width: 119px;*/		
            max-height: 30px;
            max-width: 120px;
            min-height: 20px;
			@media (min-width: 544px) {
				margin: 20px;
			}
		}
	}
	&.bg-solid {
		.logo-wrap {
			animation: tosolid 0.25s forwards;
			width: calc(100% - 30px);
			border-radius: 0 0 25px 25px;
			margin: 0;
			padding: 15px;      
			@media (min-width: 544px) {
				margin: 0;
				padding: 0;
				max-height: 60px !important
			}
		}
		// position: fixed;
		// .greetings {
		// 	animation: tosmaller 0.3s forwards;
		// }
	}
	.left {
		position: relative;
		height: 21px;
		left: 21px;
		top: 12px;
		border-radius: 32px;
	}
	.greetings {
		// margin: 60px 24px 24px;
		height: 60px;
		display: flex;
		#hi {
			// height: 60px;
			left: 24px;
			#greet {
				height: 80px;
				display: flex;
				align-items: center;
				font-family: "Rubik";
				font-style: normal;
				font-weight: 500;
				font-size: 19px;
				line-height: 24px;
				color: $white;
				svg {
					padding-right: 8px;
					circle:nth-child(1) {
						stroke: var(--activity_background);
					}
					circle:nth-child(2) {
						stroke: var(--greeting_text_color);
					}
				}

				.name {
					// // width: 196px;
					// height: 36px;
					// font-family: "Rubik";
					// font-style: normal;
					// font-weight: 500;
					// font-size: 24px;
					// line-height: 150%;
					// justify-content: center;
					// display: flex;
					// align-items: center;
					//color: $activity-right-bkg;
					color: var(--greeting_text_color);
					text-transform: capitalize;
					margin-left: 5px;
				}
			}
		}
		.avatar {
			border-radius: 50%;
			margin: 0 12px 0 20px;
			width: 44px;
		}
		img {
			border-radius: 50%;
			top: 6%;
			left: 6%;
			height: 88%;
			width: 88%;
		}
	}
}

@keyframes tosolid {
	0% {
		background: rgba(0, 0, 0, 0.3);
		height: 150px;
	}
	100% {
		background: $black-2;
		height: 110px;
		//background: linear-gradient(to top, #2F0743, #41295a);
	}
}
@keyframes tosmaller {
	// 0% {
	// 	margin: 60px 24px 24px;
	// }
	// 100% {
	// 	margin: 30px 24px 24px;
	// }
}
@keyframes totransparent {
	0% {
		background: $black-2;
		height: 110px;
	}
	100% {
		background: rgba(0, 0, 0, 0.3);
		height: 150px;
	}
}
@keyframes tobigger {
	// 0% {
	// 	margin: 30px 24px 24px;
	// }
	// 100% {
	// 	margin: 60px 24px 24px;
	// }
}
