
.row.badges {
	margin: 0;
	display: flex;
	justify-content: space-around;
	// background: linear-gradient(
	// 	360deg,
	// 	rgb(65, 41, 90) 10%,
	// 	rgba(255, 214, 221, 0) 100%
	// );
	padding: 20px 22px 70px;
	border-radius: 0px;
	margin-bottom: -60px;
	padding-top: 15px !important;

	svg {
		width: 100px;
		rect {
			height: 105px;
			width: 115px;
		}
	}
}
.profile-page .row.badges {
	background: none;
	margin-bottom: 140px;
    flex-wrap: wrap;
}

.badge {
	transition: 0.7s all;
	opacity: 0.5;
    transform-origin: center;
    transform: scale(0.9);
	cursor: pointer;
	width: calc(100vw / 3);
	img {
		max-width: 60px;
		padding: 10px;
		background: #fff;
		border: 2px solid var(--activity_background);
		border-radius: 50%;
		margin-bottom: 5px;
	}
	&.active {
		opacity: 1;
		transform: scale(1);
	}
}
p.badge-name {
	font-family: "Rubik";
	padding: 0;
	margin: 0;
	color: var(--module_name);
	font-size: 14px;
}

.swiper-button-prev, .swiper-button-next {
	background: url('../assets/prev.svg');
	width: 20px;
	height: 20px;
	background-size: contain;
    position: absolute;
    top: 50%;
    margin-top: -2px;
	z-index: 10;
	cursor: pointer;
}
.swiper-button-prev {
	left: 10px;
	transform: rotate(180deg);
}
.swiper-button-next{
	right: 10px;
}
.swiper-button-disabled {
	opacity: 0.5;
}

.swiper-container {
	max-width: calc(100vw - 80px);
	position: initial!important;
	padding: 20px 0 10px 0 !important;
	overflow: visible!important;
}
.slider-badges {
	position: relative;
}
