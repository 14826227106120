.full-dashboard {
	header {
		@include header-leaderboard;
		position: fixed;
	}
	#dot {
		position: absolute;
		transform: translate(calc(50% - 7px), 10px);
		z-index: 1;
	}
	.full-leaderboard {
		background: url(../assets/Rectangle.svg) no-repeat;
		background-size: 100%;
		position: relative;
		overflow-y: hidden;
		border-radius: 25px;
		margin: 70px 10px 50px;
		padding: 15px 15px 20px;
		text-align: left;
		@media screen and (min-width: $medium) {
			padding: 20px;
		}
		@media screen and (min-width: $large) {
			padding: 36px;
		}
		ol {
			counter-reset: item;
		}
		li {
			list-style: none;
			counter-increment: item;
			margin-bottom: 5px;
			&:first-child {
				margin-top: 16px;
				.name-card {
					.main {
						&::before {
							content: url(../assets/gold-medal.svg);
							border: none;
							width: auto;
							padding: 0;
						}
					}
				}
			}
			&:nth-child(2) {
				.name-card {
					.main {
						&::before {
							content: url(../assets/silver-medal.svg);
							border: none;
							width: auto;
							padding: 0;
						}
					}
				}
			}
			&:nth-child(3) {
				.name-card {
					.main {
						&::before {
							content: url(../assets/bronze-medal.svg);
							border: none;
							width: auto;
							padding: 0;
						}
					}
				}
			}
		}
		.main:before {
			content: counter(item);
			display: inline-block;
			border-radius: 50%;
			padding: 8px 12px;
			margin-right: 12px;
			border: 1px solid rgba(0, 0, 0, 0.15);
			font-family: "Rubik";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			text-align: center;
			color: $black-2;
		}
		.name-card {
			display: flex;
			background: $white;
			border: 1px solid rgba(0, 0, 0, 0.15);
			box-sizing: border-box;
			border-radius: 25px;
			margin: 4px 0;
			padding: 22px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			align-items: center;
			color: $black-1;
			&.me {
				// background: var(--leaderboard_background);/*linear-gradient(
				// 	268.23deg,
				// 	$highlight-color-1 0%,
				// 	$highlight-color-2 100%
				// );*/
				background: var(--activity_background);

				* {
					color: var(--greeting_text_color);
				}

				.main {
					&::before {
						color: var(--greeting_text_color);
						border-color: var(--greeting_text_color);
					}
				}
				.compid {
					color: $black-1;
				}
			}
		}
		.main {
			display: flex;
			align-items: center;
			.avatar {
				border-radius: 50%;
				width: 45px;
				margin-right: 12px;
			}
			.points {
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 13px;
				line-height: 140%;
				color: $grey-2;
				margin: 0 0 0 7px;
			}
			.name {
				margin: 0;
				font-family: "Rubik";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 19px;
				color: $black-2;
				text-transform: capitalize;
			}
			.compid {
				font-family: "Rubik";
				font-style: normal;
				font-weight: 400;
				font-size: 12px;
				line-height: 14px;
				color: $grey-6;
				.logo {
					width: 14px;
					margin-right: 6px;
				}
			}
		}
		&::before {
			content: "";
			display: block;
			width: 100%;
			height: 1000vh;
			background-color: $grey-5;
			position: absolute;
			left: 0;
			top: 115px;
			z-index: -1;
		}
	}
	footer {
		// li:not(:nth-child(3)) {
		// 	opacity: 0.3;
		// }
	}
}
.hide-last {
	display: none;
}
