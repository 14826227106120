.activities {
	position: relative;
	.points-box {
		display: block;
		// background-image: linear-gradient(to right, #6253e1, #e337ff, #882aff);
		background: #0d0b1d;
		border-radius: 25px 25px 0 0;
		padding: 6px 20px;
		font-family: "Rubik";
		color: #fff;
		font-size: 0.9em;
		.bold {
			font-weight: bold;
			color: #ff9900;
		}
	}
	.card-three {
		background: $module-background;
		border-radius: 25px 25px 0px 0px;
		margin: -30px 0px 60px 0px;
		padding: 18px 24px 24px;
		padding-bottom: 45px !important;
		.business-card-btn {
			display: none;
			&.view-all {
				position: absolute;
				display: block;
				width: fit-content;
				background: blue;
                background: var(--buttons_background) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
				/*background: linear-gradient(180deg, #e337ff 0%, #882aff 100%);*/
				padding: 4px 20px !important;
				top: 22px;
				//color: #fff;
                color: var(--buttons_color);
				right: 22px;
			}
		}

		@media screen and (min-width: $large) {
			padding-bottom: 60px;
		}
		.dash {
			border: 1px solid rgba(0, 0, 0, 0.15);
			width: 26px;
			margin: 0 auto;
		}

		div:nth-of-type(3) .quiz-card {
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			&.disabled {
				&::after {
					border-top-left-radius: 25px;
					border-top-right-radius: 25px;
				}
			}
		}
		div:nth-last-of-type(2) .quiz-card {
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
			&.disabled {
				&::after {
					border-bottom-left-radius: 25px;
					border-bottom-right-radius: 25px;
				}
			}
		}

		.top-three {
			margin: 8px auto 12px;
			display: flex;
			justify-content: space-between;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 140%;
			color: $black-1;
			a {
				font-size: 14px;
				color: $dark-purple;
				text-decoration: none;
			}
		}
		.quiz-card {
			position: relative;
            // ARGIT
			// background: $activity-card-bkg;
            background: var(--activity_background);            
			background-repeat: no-repeat;
			background-size: cover;
			box-shadow: var(--activity_background);
			margin-bottom: 4px;
			background-blend-mode: difference;
			overflow: hidden;

			a,
			.link-to {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-around;
				width: 100%;
				text-decoration: none;
			}
			.left {
				margin: 12px;
				width: 63px;
				min-width: 63px;
				height: 64px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: #fffcf4;
				border-radius: 15px;
				img {
					width: 40px;
				}
				.left-disabled {
					display: none;
				}

				&.completed {
					img {
						&.preview-image {
							width: 90% !important;
							overflow: hidden !important;
						}
					}
				}
			}
			.middle {
				min-width: 120px;
				.mid-flex {
					display: flex;
					align-items: center;
				}
				.quiz-name {
					margin: 0;
					margin-top: -14px;
					margin-left: 10px;
					text-decoration: none;
					text-align: left;
					font-family: "Rubik";
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
                    // ARGIT
					//color: $white;
                    color: var(--activity_text_color);
				}
				.points {
					visibility: visible;
					position: absolute;
					font-family: "Rubik";
					font-style: normal;
					font-weight: 400;
					font-size: 12px;
					line-height: 14px;
                    // ARGIT                    
					//color: $activity-points-color;
                    opacity: 0.7;
					color: var(--activity_text_color);
				}

				img {
					visibility: hidden;
				}
				.quiz-description {
					font-weight: normal;
					font-size: 12px;
					color: $grey-2;
					margin: 6px 0px;
				}
				&.completed {
					.quiz-name {
						// opacity: 0.5;
						margin-top: -20px;
					}
					.points {
						visibility: visible;
						left: 0;
						z-index: 100;
						margin-left: 97px;
						margin-top: 5px;
						animation-duration: 1.5s;
						animation-name: fadeIn;
						@keyframes fadeIn {
							0% {
								opacity: 0;
							}
							100% {
								opacity: 1;
							}
						}
						// color: $white;
					}
					img {
						margin-right: 6px;
						visibility: visible;
					}
				}
			}
			.right {
				margin-left: auto;
                                
				//background: $activity-right-bkg;
                background: var(--buttons_background);
                //background: var(--buttons_background) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
                
				border-radius: 15px;
				width: 63px;
				height: 63px;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 12px;
				svg {
					path {
                        
						//stroke: $white;
                        stroke: var(--buttons_color);
					}
				}
				.check-mark,
				.right-disabled {
					display: none;
				}
				&.completed {
					background: $comleted-activity-mark-bkg;
					border-radius: 15px;
					// svg {
					// 	display: none;
					// }
					.check-mark {
						display: block;
						svg {
							path {
								stroke: $green-check-mark;
							}
						}
						animation-duration: 1.5s;
						animation-name: fadeIn;
					}
				}
			}
			&.completed {
				// pointer-events: none;
				
				&::after {
					content: '';
					pointer-events: none;
					background: rgba(0,0,0,0.35) !important;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
				}
				&.incorrect {
					.middle.completed {
						.points {
							display: none;
						}
					}
					.right.completed {
					}
				}
				// p.quiz-name {
				// 	color: rgba(0, 0, 0, 0.7);
				// }
				// .right svg path {
				// 	stroke: rgba(0, 0, 0, 0.7);
				// }
				// &:after {
				// 	content: "Completed";
				// 		font-size: 17px;
				// 	color: #fff;
				// 	left: -2px;
				// 	line-height: 90px;
				// 	width: 100%;
				// 	height: 100%;
				// 	background-color: rgba(65, 1, 245, 0.95);
				// 	border: 2px solid rgba(65, 1, 245, 1);
				// 	position: absolute;
				// 	border-radius: 20px;
				// }
				// &:before {
				// 	content: attr(data-points);
				// 	background: #f1c22e;
				// 		border: 3px solid #c88f00;
				// 	width: 30px;
				// 	height: 30px;
				// 	position: absolute;
				// 	z-index: 1;
				// 	border-radius: 50%;
				// 	line-height: 30px;
				// 		color: #c88f00;
				// 	right: -10px;
				// 	top: -10px;
				// 	animation: coin-animation 1.5s forwards;
				// }
			}

			&.disabled {
				// &:after {
				// 	left: -2px;
				// 	top: 0;
				// 	position: absolute;
				// 	content: "Scan QR to unlock";
				// 	font-size: 17px;
				// 	color: #fff;
				// 	line-height: 90px;
				// 	width: 100%;
				// 	height: 100%;
				// 	background: rgba(65, 41, 90, 0.95);
				// 	border: 2px solid #41295a;
				// 	border-radius: 20px;
				// }
				.left {
					z-index: 1;
				}
				img {
					display: none;
				}
				.left-disabled {
					display: block;
					background: $activity-disabled-lock-bkg;
					box-shadow: $activity-disabled-lock-shadow;
					backdrop-filter: blur(5.5px);
					border-radius: 15px;
				}
				.right-disabled {
					display: block;
					border-radius: 15px;
					z-index: 1;
				}
				.activity-link {
					pointer-events: none;
				}
			}
		}
		.wallet {
			display: none;
		}
	}
	.db-buttons {
		display: flex;
		justify-content: space-around;
		button {
			@include button;
		}
	}

	.card-three .wallet .wallet-flex #new-avatar img {
		max-width: 45px;
		margin-top: 7px;
		margin-left: 0px;
	}
	.wallet-card img,
	.wallet-card .emoji {
		width: 35px !important;
		position: relative;
		font-size: 20px;
		line-height: 50px;
		padding: 0;
		margin-left: 7px;
	}
	.card-three .wallet {
		display: flex;
		background: #fff;
		margin: 10px auto;
		box-shadow: 0px 0px 7px #cccccc;
		padding: 40px 10px 10px 10px;
		border-radius: 20px;
		justify-content: center;
		pointer-events: none;
		margin-top: 30px !important;
		position: relative;
		overflow: hidden;
	}
	.wallet-card {
		border-radius: 10px;
		display: block;
		background: rgba(255, 255, 255, 0.25);
		padding: 0px;
		overflow: hidden;
		width: 50px;
		pointer-events: none;
		height: 50px;

		&.bio {
			display: none;
		}

		&.earned-reward {
			background-size: contain;
		}

		&.avatar {
			background-size: cover;
		}
	}

	footer {
		// li:not(:nth-child(2)) {
		// 	opacity: 0.3;
		// }
	}
	.wallet {
		margin: 0 -24px !important;
		// background: #541d94 !important;
		border-radius: 0 !important;
		// background: linear-gradient(to right, #6253e1, #e337ff, #882aff) !important;
		background: var(--rewards_and_wallet_background)!important;//$black-2 url("../assets/bg-overlay.png") !important;
		background-blend-mode: color-dodge;
	}
	.wallet:after {
		content: "Collectibles";
		display: block;
		background: #fff;
		// border-radius: 20px 20px 0 0;
		padding: 6px 20px;
		font-family: "Rubik";
		color: #000;
		font-size: 0.9em;
		box-shadow: 0px -3px 20px rgb(0 0 0 / 5%);
		height: 100%;
		position: absolute;
		left: 0;
		top: 0px;
		right: 0;
		height: 16px;
	}
	.wallet-button {
		display: block;
		// background: rgba(0,0,0,0.1);
		margin-top: -7px;
		width: 35px;
		right: 0;
		position: absolute;
		height: 70px;
		z-index: 100;
	}
	.wallet-svg {
		width: 8px;
		margin-top: 22px;
		height: auto;
	}
	.wallet-link {
		color: #000 !important;
		font-weight: bold;
		font-family: "Rubik";
		font-size: 10px;
	}
}

@keyframes coin-animation {
	0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
	}
}
