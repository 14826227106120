.activity {
	.activity-link {
		text-decoration: none;
	}

	.quiz-card {
		&.bio-hidden {
			display: none;
		}
		&.disabled {
			opacity: 1;
			position: relative;
			&::after {
				left: 0;
				top: -2px;
				position: absolute;
				content: "Scan QR to unlock";
				font-family: "Rubik";
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				color: $white;
				line-height: 80px;
				width: calc(100% - 4px);
				height: 98%;
				border: 2px solid $dark-purple;
				/*background: $activity-card-bkg;*/
				background: var(--activity_background);
				/*box-shadow: $activity-card-shadow;*/
				// border-radius: 25px 25px 0px 0px;
			}

			.card-three {
				.right-disabled,
				.left-disabled {
					display: block;
					z-index: 1;
				}
			}
		}
	}

	// div:nth-of-type(3) .quiz-card {
	// 	&.disabled::after {
	// 		border-radius: 25px 25px 0 0;
	// 	}
	// }

	.left {
		img {
			width: 64px;
		}
	}
}
