.modalBg {
	width: 100%;
	height: auto;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	left: 0;
	top: 212px;
	.modalContainer {
		width: 80%;
		height: auto;
		background: $dark-blue;
		color: $white;
		.flex-col {
			flex-direction: column;
			label {
				margin: 15px;
				@include label;
				color: $dull-lavender;
			}
			input {
				width: 225px;
				height: 44px;
				left: 30px;
				background: $white;
				box-shadow: 0px 12px 12px rgba(52, 84, 122, 0.0561594);
				border-radius: 100px;
				font-size: 22px;
				text-align: center;
				margin: 7px;
			}
		}
		.titleCloseBtn {
			button {
				position: absolute;
				color: $white;
				text-align: end;
				width: 100%;
			}
			button:hover {
				background-color: inherit;
				transform: none;
				box-shadow: none;
			}
		}
		title {
			p {
				margin: 0;
			}
		}
	}
	.timer-wrap {
		justify-content: center;
		margin: 15px 0 0;
	}

	.foundMatch-submit {
		@include btn-secondary;
		width: 160px;
		height: 45px;
		padding: 0;
	}
	.popup-inner {
		position: absolute;
		transform: translate(-50%, -100%);
		left: 50%;
		width: calc(100% - 83px);
		height: 200px;
		background-color: $white;

		button {
			@include btn-primary;
			position: absolute;
			width: 170px;
			height: 45px;
			padding: 0;
			transform: translate(-50%);
			left: 50%;
		}
	}
}
.popup {
	background: $white;
	position: absolute;
	border-radius: 20px;
	width: 80%;
	max-width: $desktop-modal-width;
	transform: translate(-50%);
	transform-origin: center center;
	left: 50%;
	top: 265px;
	height: 331px;
	z-index: 999;

	.module-closer {
		position: absolute;
		color: $white;
		font-size: 20px;
		top: 10px;
		right: 20px;
		transform: scale(0.8);
		transition: 0.25s all;
		&:hover, &:active {
			transform: scale(0.7);
		}
	}
	
	p {
		font-family: "Rubik";
		// padding: 20px;
	}

	h2 {
		text-align: center;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: #000;
	}

	.popup-inner {
		transform: translate(-50%, -50%);
		left: 50%;
		top: 45%;
		width: 70%;
		position: absolute;
		&.rounded {
			p {
				font-family: "Rubik";
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 25px;
				text-align: center;
				color: $black-2;
			}
		}
	}
	@media screen and (min-width: 409px) {
		height: 326px;
	}
	@media screen and (min-width: 499px) {
		top: 260px;
		height: 290px;
	}
	button {
		@include btn-primary;
		width: 50%;
		padding: 10px;
		margin: 35px auto 0;
		height: 35px;
		background: var(--buttons_background);
		border-radius: 99px;
		border: 0;

		&.redeem-btn {
			background-color: #fff !important;
			padding: 4px 20px !important;
			color: #000 !important;
		}
	}
}

.visible.popup {
	animation: showModal 0.75s forwards;
	animation-timing-function: cubic-bezier(0.2, -2, 0.8, 2);
}

.hidden.popup {
	animation: hideModal 0.75s forwards;
	animation-timing-function: cubic-bezier(0.2, -2, 0.8, 2);
}

@keyframes showModal {
	0% {
		opacity: 0;
		transform: scale(0) translate(-100%);
	}
	100% {
		opacity: 1;
		transform: scale(1) translate(-50%);
	}
}

@keyframes hideModal {
	0% {
		opacity: 1;
		transform: scale(1) translate(-50%);
	}
	100% {
		opacity: 0;
		transform: scale(0) translate(-100%);
	}
}
#error {
	font-size: 10px;
	color: $red;
	line-height: 12px;
	margin-bottom: -20px;
	margin-top: 10px;
}

input#activation-input {
	padding: 10px;
	font-size: 16px;
	text-align: center;
}


// NOTIFICATIONS
.visible.popup.notification {
    background: var(--loader_background);
}

.popup.notification button {
    background: #fff;
    color: #000;
    margin-top: 0px;
    font-size: 14px;
    width: auto;
    min-width: 150px;
}

.popup.notification p, .popup.notification h2 {
    color: var(--greeting_text_color) !important;
}

.popup.notification h2 {
    font-size: 14px;
}