.video-page {
	header {
		@include header-leaderboard;
		position: fixed;
		z-index: 100;
		top: 0;
	}
	// height: calc(100vh - 120px);
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	padding-top: 90px;
	min-height: 100vh;
	// background: conic-gradient(
	// 	from 0deg at 50% 50%,
	// 	#ffe710 0deg,
	// 	#ffbd20 360deg
	// );
	// padding-bottom: 162px;

	// @media screen and (min-width: $medium) {
	// 	padding-bottom: 218px;
	// }
	// @media screen and (min-width: $large) {
	// 	padding-bottom: 230px;
	// }
	// p {
	// 	@include names;
	// 	margin: 0;
	// }
	.video-wrapper {
		width: 100%; // 85% of the screen
		height: auto; // Reset height
		margin: auto;
		position: relative;
		top: 0%;
		margin-top: 60px;
		height: 220px;
		z-index: 100;
	}

	#movie_player {
		padding-top: 56.25%; // Percentage ratio for 16:9
		position: relative; // Set to relative
	}

	.video-wrapper > div {
		width: auto !important;
		display: flex; // Scaling will occur since parent is relative now
		justify-content: center;
	}

	button {
		@include button;
	}

	.card-two {
		position: absolute;
		.btm-featured {
			display: none;
		}

		.top-featured {
			display: none;
		}
	}

	.skip-btn {
		position: absolute;
		background: var(--buttons_background) !important;
		font-family: 'Rubik';
		border: none !important;
		top: 80%;
		left: 50%;
		z-index: 999999;
		pointer-events: all;
		transform: translate(-50%,-50%);

		&:hover, &:focus {
			transform: translate(-50%,-50%);
		}
		// .top-featured {
		// 	padding-top: 5px;
		// }
		// .btm-featured {
		// display: none;
		// }
	}
	footer {
		li {
			opacity: 0.3;
		}
	}
	.task-hidden {
		visibility: hidden;
	}
}
