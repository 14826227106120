.board {
	list-style: none;
	counter-reset: item;
	margin: 0 8px;
	background: url(../assets/Rectangle.svg);
	background-size: cover;
	border-radius: 32px;
	padding: 8px;
	@media screen and (min-width: $medium) {
		padding: 20px;
	}
	@media screen and (min-width: $large) {
		padding: 36px;
	}
	li {
		counter-increment: item;
		margin-bottom: 5px;
		.name-card {
			display: flex;
			background: $white;
			border-radius: 20px;
			margin: 16px 0;
			padding: 22px 16px;
			text-transform: capitalize;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			align-items: center;
			color: $black-1;
		}
		&:before {
			border: 1.5px solid $grey-4;
			border-radius: 50%;
			color: $grey-2;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 18px;
			text-align: center;
			padding: 4px 9px;
			margin-right: 16px;
			width: 8px;
		}
		.medal {
			margin-left: auto;
		}
		.main {
			display: flex;
			align-items: center;
			.avatar {
				border-radius: 50%;
				width: 60px;
				margin-right: 12px;
			}
			.points {
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 140%;
				color: $grey-2;
				margin: 4px 0 0;
			}
			.name {
				margin: 0;
			}
		}
	}
	.board {
		margin: 0;
		display: flex;
		flex-direction: column;

		li {
		}
		li:before {
			margin-right: 10px;
			content: counter(item);
			background: lightblue;
			border-radius: 100%;
			color: $white;
			width: 1.2em;
			text-align: center;
			display: inline-block;
		}
		// li {
		// 	text-align: left;
		// 	margin-top: 7px;
		// 	left: 25px;
		// 	filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
		// 	font-family: $li-font;
		// 	font-size: 14px;
		// 	font-weight: bold;
		// 	line-height: 19px;
		// 	color: $li-color;

		.compid {
			font-weight: normal;
			font-size: 14px;
			line-height: 14px;
			display: flex;
			align-items: center;
			color: $grey-1;
			opacity: 0.8;
		}
	}
}
