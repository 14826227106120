.profile {
	.hide-details {
		display: none;
	}

	background: transparent;
	padding: 0px 0 10px;

	.profile-titles,
	.home-titles {
		margin: 0;
		align-items: center;
		text-align: left;
		padding-left: 20px;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		color: $white;

		&.mb-11 {
			margin-bottom: 11px;
		}
	}

	.wallet {
		// margin-top: 20px;
		// background: $module-background;
		border-radius: 25px;
		padding: 10px 0 20px 0;
		// margin-bottom: 30px;
		padding-bottom: 0px;

		.wallet-button {
			display: none !important;
		}

		.profile-titles {
			color: $solid-black;
		}

		.wallet-flex {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: 10px 20px;


			.wallet-card.activity-nft::after {
				opacity: 0.05;
			}
			.wallet-card.activity-nft.earned-reward {
				background-size: contain;
				background-color: var(--nft_color_three);
				background-position: center;

				.reward-name {
					font-size: 1.1em !important;
					line-height: 1.1em !important;
				}
			}

			.wallet-card.activity-nft.earned-reward::after {
				opacity: 0.4;
			}
		}

		.wallet-card {
			.wallet-image {
				backdrop-filter: blur(5.5px);
				border-radius: 15px;
				width: 100%;
				height: 100%;
				background-color: #fff;
			}

			width: 47%;
			max-width: 205px;
			padding-top: 0;
			height: auto;
			margin-bottom: 20px;
			background: var(--buttons_background);
			/*
			background: linear-gradient(
				95.46deg,
				$footer-first 41.4%,
				$footer-second 60.9%
			);
			*/
			box-shadow: $activity-disabled-lock-shadow;
			-webkit-backdrop-filter: blur(5.5px);
			backdrop-filter: blur(5.5px);
			border-radius: 15px;

			&#voucher {
				p {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-bottom: 12px;
					font-family: "Rubik";
					font-style: italic;
					font-weight: 400;
					font-size: 16px;
					line-height: 19px;
					color: $white;

					&:first-of-type {
						font-size: 16px;
						color: $white;
						font-weight: 700;
						font-style: normal;
						margin: 30px auto 20px;
					}
				}

				button {
					@include btn-primary;
					width: 65%;
					padding: 10px 20px;
					height: 40px;
					// background: linear-gradient(268.23deg, #e337ff 0%, #882aff 100%);
					border-radius: 99px;
					margin: 0 auto;
				}

				.emoji {
					font-size: 35px;
					margin-bottom: 20px;
				}
			}

			&.activity-nft {
				overflow: hidden;
				position: relative;
				min-height: 160px;

				p {
					color: var(--buttons_color);
					font-size: 1.1em;
					line-height: 1.1em;
					font-weight: bold;
					font-family: "Rubik";
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					z-index: 10;
					margin: 0;
				}

				&::after {
					position: absolute;
					content: '';
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					opacity: 0.25;
					background: black;
				}

				&.download {
					background: var(--nft_color_one);
					// background: linear-gradient(43deg, var(--response_gradient_color_two), var(--response_gradient_color_one));
				}

				&.video {
					background: var(--nft_color_three);
				}

				&.avatar {
					background-size: cover;
					&::after {
						opacity: 0;
					}
				}

				&.bio {
					display: none;
					background: var(--nft_color_two);
					.emoji {
						opacity: 0.4 !important;
						font-size: 140px;
					}
					&::after {
						opacity: 0.5;
					}
				}

				&.reward {

					// &:after {
					// 	content: '';
					// 	width: 100%;
					// 	height: 100%;
					// 	left: 0;
					// 	top: 0;
					// 	background-color: rgba(0,0,0,0.4);
					// 	z-index: 1;
					// 	border-radius: 10px;
					// }
					img.emoji {
						opacity: 0.1 !important;
					}
				}

				.reward-name {
					font-size: 1.6em;
					line-height: 1.05em;
				}

				.emoji {
					font-size: 100px;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					margin-bottom: 20px;
					opacity: 0.3;
				}

				&#bio {
					p {
						font-size: 5vw;
						text-transform: capitalize;
					}
				}
			}
		}
	}

	.ranks {
		display: none;
	}

	.links {
		margin: 35px 0;
		width: 100%;
		display: flex;
		flex-direction: column;

		a {
			padding: 5px;
			@include links;
		}

		a:visited {
			color: $link-color;
		}
	}

	.card-three {
		background: $white;
		border-radius: 32px;
		margin: 0 0 30px;
		padding: 24px;

		@media screen and (min-width: $large) {
			padding-bottom: 60px;
		}

		.top-three {
			display: flex;
			justify-content: space-between;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 140%;
			color: $black-1;

			a {
				font-size: 14px;
				color: $dark-purple;
				text-decoration: none;
			}
		}

		.quiz-card {
			border: 2px solid $grey-5;
			box-sizing: border-box;
			margin: 16px 0px;
			background: $white;
			border-radius: 20px;
			display: flex;
			justify-content: start;
			align-items: center;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			padding: 8px 28px 8px 8px;

			a {
				display: flex;
				align-items: center;
				width: 100%;
				text-decoration: none;
			}

			.left {
				margin: 6px 16px 0 0;
			}

			.middle {
				.quiz-name {
					margin: 0;
					color: $black-1;
					text-decoration: none;
				}

				.quiz-description {
					font-weight: normal;
					font-size: 12px;
					color: $grey-2;
					margin: 6px 0px;
				}
			}

			.right {
				margin-left: auto;
			}
		}
	}

	.popup {
		// background: linear-gradient(
		// 	114.2deg,
		// 	rgba(117, 73, 174, 0.9) 15.5%,
		// 	rgba(93, 37, 166, 1) 93.71%
		// );
		// background: linear-gradient(
		// 	95.46deg,
		// 	$footer-first 41.4%,
		// 	$footer-second 60.9%
		// );
		// background: var(--activity_background);
		background: var(--background);
		top: 20%;
		max-width: $desktop-modal-width;
		position: fixed;
		height: 460px;
		box-shadow: 0px 0px 20px rgba(0,0,0,0.4);

		img {
			width: 120%;
			max-width: 400px;
			transform: translate(-50%);
			left: 50%;
			position: relative;
		}

		.popup-inner {

			h2 {
				color: var(--buttons_color);
				font-size: 24px;
			}
			.flip-side {
				a[href="#/profile"] {
					text-decoration: none;
				}
			}

			.wallet-card {
				background-color: transparent;
				margin-top: 50px;
			}
			.wallet-image {
				backdrop-filter: blur(5.5px);
				border-radius: 15px;
				margin-top: 0;
				width: 110%;
				height: 110%;
			}
		}

		button:not(.download-btn, .show-details, .redeem-btn) {
			display: none;
		}

		.download-btn,
		.show-details {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 12px 20px;
			height: 44px;
			background: $white;
			border-radius: 20px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			color: $dark-purple;
			border: none;
			width: 170px;
			padding: 12px 16px;
		}

		.popup-text,
		.company-details {
			color: $white;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			line-height: 22px;

			#company-name {
				font-weight: bold;
				text-decoration: underline;
				text-transform: uppercase;
			}
		}

		.emoji {
			font-size: 130px;
			margin-bottom: 20px;
			opacity: 0.8;
		}

		@media screen and (min-width: 409px) {
			height: 500px;
		}

		@media screen and (min-width: 479px) {
			height: 600px;
		}

		@media screen and (min-width: 569px) {
			height: 700px;
		}
	}

	footer {
		li:not(:last-child) {
			opacity: 0.3;
		}
	}

	.wallet-btn-wrapper {
		display: flex;
		margin: -10px 0 25px 25px;

		.applewalletcard {
			width: 100px;
			height: auto;
			// margin-top: -100px;
			// margin-bottom: 200px;
		}
	}
}