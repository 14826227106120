.airdropsList {
	font-family: Rubik;
	padding-left: 20px;
	margin-top: 0;
	text-align: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 10px 20px;
	margin-top: -20px;

	.airdrop__item {
		width: 47%;
		max-width: 205px;
		height: auto;
		margin-bottom: 20px;
		// background: var(--buttons_background);
		box-shadow: inset 0px -2.75px 2.5px rgba(138, 56, 244, 0.15), inset 0px 1.5px 2.5px rgba(255, 255, 255, 0.15);
		-webkit-backdrop-filter: blur(5.5px);
		backdrop-filter: blur(5.5px);
		border-radius: 15px;

		img {
			-webkit-backdrop-filter: blur(5.5px);
			backdrop-filter: blur(5.5px);
			border-radius: 15px;
			width: 100%;
			height: 100%;
		}
	}

	.empty-airdrops {
		margin-top: 40px;
		font-size: 16px;
	}

	.airdrop__item {
		// margin-top: 20px;
		// display: flex;

		.airdrop__name {
			color: #fff;
			font-size: 20px;
			margin-bottom: 5px;
		}

		.airdrop__description {
			color: #999;
			margin-bottom: 20px;
			font-size: 14px;
		}

		// .airdrop__image {
		// 	img {
		// 		max-width: 200px;
		// 	}
		// }

		.airdrop__right {
			margin-left: 20px;
		}

		.airdrop__users {

			.airdrop__users__title {
				margin-bottom: 5px;
				font-size: larger;
			}

			.airdrop__user {}
		}

	}

}

.dashboard .airdropsList {
	display: none;
}