#signin-header {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	text-align: center;
	color: $white;
	margin-bottom: 20px;
}

.lagoon-logo {
	max-height: 34px;
	margin: 40px 0 10px 0;
	max-width: 222px;
}
.signUp {
	background: var(--background);
}
#signin-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100vh;
	input {
		max-width: 222px;
		height: 30px;
		font-size: 16px;
		text-align: center;
		margin: 7px;
		background: no-repeat;
		border: 0;
		font-family: Rubik;
		font-style: normal;
		font-weight: 400;
		line-height: 19px;
		display: flex;
		align-items: center;
		border-bottom: 2px solid $footer-shadow-2;
		padding: 5px 16px;
		color: $white;
		&::placeholder {
			// background: rgba(12, 9, 42, 0.16);
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			display: flex;
			align-items: center;
			color: $placeholder-color;
		}
		&:focus {
			outline: none;
			border-bottom: 2px solid var(--buttons_background);
			cursor: pointer;
		}
	}
	label {
		// margin-right: 15px;
		margin-top: 20px;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 11px;
		line-height: 150%;
		color: $white;
		&[for="company"] {
			margin-right: 7px;
		}
	}
	button {
		@include btn-secondary;
		padding: 0;
		border-radius: 99px;
		line-height: 19px;
		margin: 10px auto 0;
		width: 168px;
		height: 57px;
		border: 0;
		font-weight: 500;
		cursor: pointer;
		background: var(--buttons_background);
		font-size: 18px;
		/*
		background: linear-gradient(
			268.23deg,
			$highlight-color-1 0%,
			$highlight-color-2 100%
		);
		*/
		color: var(--buttons_color);
		&:hover {
			// background-color: $btn-hover;
			transform: translateY(1px);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
		}
		&:active {
			transform: scale(0.95, 0.95);
		}
		&.no-button {
			background: transparent;
			margin-top: 20px;
			width: 300px;
		}
	}

	p:not(#signin-header) {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 11px;
		line-height: 150%;
		text-align: center;
		color: $required-message;
		margin-top: 2px;
	}

	.react-tel-input {
		max-width: 251px;
		.form-control {
			border-radius: 0;
			width: 100%;
			margin: 0;
			max-width: none;
		}
	}
	.react-tel-input .flag-dropdown {
		background-color: transparent;
		border: 0;
	}

}
.registration_consent {
	display: flex;
	color: #fff;
	max-width: 300px;
	text-align: left;
	input {
		float: left;
		margin-top: -3px!important;
		padding-top: 0!important;
		width: 20px;
	}
	input[type="checkbox"] {
		width: 30px;
		min-width: 18px;
		accent-color: var(--buttons_background);
	}
}
.registration_agree {
	color: #fff;
	max-width: 300px;
	font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 150%;
	margin-top: 25px;
}
