.progressVisualRewards {
	left: 5%;
    width: 90%;
	margin-top: 20px;
	position: relative;
	height: 15px;
	margin-bottom: -20px;
	z-index: 1;

	div {
		color: $white;
		font-family: 'Rubik';
		font-size: 10px;
		display: block;
		line-height: 15px;
		font-weight: bold;
		position: absolute;
		top: 5px;

		&::before {
			content: '';
			width: 18px;
			display: block;
			height: 18px;
			top: -2px;
			position: absolute;
			background: url('../assets/coin.png');
			z-index: 1;
			background-size: contain;
			filter: grayscale(1);
			left: -8px;
		}

		&::after {
			content: '';
			width: 2px;
			z-index: 0;
			background: $white;
			display: block;
			height: 3px;
			position: absolute;
			left: 0;
			top: -8px;
			opacity: 0.5;
		}

		span {
			position: absolute;
			font-size: 0.85em;
			line-height: 1em;
			font-weight: normal;
			width: 90px;
			transform: translateX(-50%);
			top: -18px;
			color: $white;
			opacity: 0.5;
		}

		&.active {
			span {
				opacity: 1;
				color: $orange;
			}
			&::before {
				filter: none;
			}
			&::after { 
				opacity: 1;
			}
		}
	}
}

.progressVisualFull {
	height: 25px;
	width: 90%;
	border-radius: 50px;
	margin: 15px auto;
	margin-top: 5px;
	display: flex;
	position: relative;

	&:before {
		// content: '';
		display: block;
		background: linear-gradient(45deg, rgba(255, 255, 255, 0), #fff 10%, #fff 90%, rgba(255, 255, 255, 0) 100%);
		width: 96%;
		height: 1px;
		left: 2%;
		// top: -4px;
		top: 24px;
		border-radius: 25px;
		position: absolute;
	}

	&:after {
		// content: '';
		display: block;
		background: linear-gradient(45deg, rgba(255, 255, 255, 0), #fff 10%, #fff 90%, rgba(255, 255, 255, 0) 100%);
		width: 96%;
		height: 1px;
		left: 2%;
		// top: -4px;
		top: 0;
		border-radius: 25px;
		position: absolute;
	}

	.progressVisualPart {
		transition: width 2s;
		position: relative;
		//border-right: 1px solid $white;
		background: var(--buttons_background) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)!important;

		&:first-of-type {
			border-top-left-radius: 50px;
			border-bottom-left-radius: 50px;
			height: inherit;

			border-left: 0!important;
		}

		&:last-of-type {
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
		}

		&:nth-child(2):before {
			content: '';
			position: absolute;
			background: #000;
			opacity: 0.1;
			top: 0; right: 0; bottom: 0; left: 0;

		}
		&:nth-child(3):before {
			content: '';
			position: absolute;
			background: #000;
			opacity: 0.2;
			top: 0; right: 0; bottom: 0; left: 0;

		}
		&:nth-child(4):before {
			content: '';
			position: absolute;
			background: #000;
			opacity: 0.3;
			top: 0; right: 0; bottom: 0; left: 0;

		}
		&:nth-child(5):before {
			content: '';
			position: absolute;
			background: #000;
			opacity: 0.4;
			top: 0; right: 0; bottom: 0; left: 0;

		}
		//&:last-of-type(2) {
			//// border-left: 1px solid $white;
			//border-top-right-radius: 50px;
			//border-bottom-right-radius: 50px;
		//}

		&::after {
			// content: attr(data-modulestext);
			position: absolute;
			margin-top: 30px;
			font-size: 10px;
			color: $white;
			font-family: "Rubik";
			font-weight: regular;
			transform: translate(-50%);
		}

		&.show-text {
			&::after {
				// content: attr(data-modulestext);
			}
			&:nth-of-type(2) {
				border-left: 1px solid $white;
			}
		}
	}

	.points-total {
			position: absolute;
			visibility: hidden;
			font-size: 10px;
			color: $white;
			font-family: "Rubik";
			font-weight: regular;
			transform: translateX(-20px);
			line-height: 23px;
			padding: 0 6px;
			background: #470c65;
			border: 1px solid #fff;
			height: 23px;
			margin-top: 0;
			z-index: 1;
			border-radius: 50%;

			&.show-text {
				visibility: visible;
			}
	}
}
