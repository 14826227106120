.ranks {
	margin: 0px 22px 16px;
	#points,
	#world-rank,
	#local-rank {
		padding: 22px;
		width: 43%;
		// height: 104px;
		background: $activity-disabled-lock-bkg;
		//box-shadow: $activity-disabled-lock-shadow;
		box-shadow: inset 0px -2.75px 2.5px rgba(138, 138, 138, 0.15), inset 0px 1.5px 2.5px rgba(255, 255, 255, 0.15);
		backdrop-filter: blur(5.5px);
		/* Note: backdrop-filter has minimal browser support */
		margin: 0;
		border-radius: 25px;
		display: flex;
		justify-content: space-between;
	}

	#local-rank {
		.logo {
			width: 55px;
			height: 61px;
		}
		.inside-ranks {
			.value {
				width: 36px;
				height: 30px;
				&.top {
					background-color: $green-check-mark;
					color: $black-1;
				}
				&.low {
					background-color: $red-2;
				}
				&.middle {
					background-color: $pink-1;
				}
			}
		}
	}
	#points {
		margin-right: 12px;

		.stars {
			display: flex;
			flex-direction: column;
			width: 50px;
			img {
				&:first-of-type {
					width: 28px;
					height: auto;
				}
				&:nth-of-type(2) {
					align-self: flex-end;
				}
				&:last-of-type {
					width: 13px;
					height: auto;
				}
			}
		}
	}

	padding: 9px 0;
	border-radius: 20px;
	display: flex;
	justify-content: space-around;
	.logo {
		width: 22px;
		height: 22px;
	}

	.border {
		background: $score-bkg;
		width: 2px;
	}

	.text {
		margin-bottom: 12px;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: $white;
	}
	.value {
		font-family: "Rubik";
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: $white;
		text-align: center;
		height: 30px;
		width: 31px;
		justify-content: center;
		display: flex;
		align-items: center;
		background: $score-color;
		border-radius: 50%;
	}
}
