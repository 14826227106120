.admin {
	.logout {
		display: flex;
		justify-content: end;
		button {
			border: none;
			background: none;
			margin: 16px;
		}
		p {
			font-family: "Rubik";
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 19px;
			color: rgba(255, 255, 255, 0.6);
		}
	}
}
