.bio {
	header {
		@include header-leaderboard;
		position: relative;
		// position: relative;
		z-index: 10000;
		.top {
			p {
				margin: 0 auto;
			}
		}
	}
	.email-form {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding-bottom: 150px;
		input {
			max-width: 222px;
			height: 30px;
			font-size: 16px;
			text-align: center;
			margin: 7px;
			background: no-repeat;
			border: 0;
			font-family: Rubik;
			font-style: normal;
			font-weight: 400;
			line-height: 19px;
			display: flex;
			align-items: center;
			border-bottom: 2px solid $comleted-activity-mark-bkg;
			padding: 10px;
			color: $white;
			&::placeholder {
				// background: rgba(12, 9, 42, 0.16);
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 19px;
				display: flex;
				align-items: center;
				color: $activity-points-color;
			}
			&:focus {
				outline: none;
				border-bottom: 2px solid var(--buttons_background);
				cursor: pointer;
			}
		}
		label {
			margin-right: 15px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 15px;
			line-height: 150%;
			color: $white;
			&[for="company"] {
				margin-right: 7px;
			}
		}
		button {
			@include btn-secondary;
			padding: 0;
			border-radius: 99px;
			line-height: 19px;
			margin: 23px auto 0;
			width: 168px;
			height: 57px;
			border: 0;
			font-weight: 500;
			cursor: pointer;
			background: var(--buttons_background);
			// background: $highlight-color;
			color: $white;
			&:hover {
				// background-color: $btn-hover;
				transform: translateY(1px);
				box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
			}
			&:active {
				transform: scale(0.95, 0.95);
			}
		}
	}

	p:not(.email-form) {
		font-family: Rubik;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 150%;
		text-align: center;
		color: $required-message;
		margin-top: 2px;
	}
	.btm-featured {
		display: none;
	}
	.top-featured {
		padding: 10px 0;
	}
}
