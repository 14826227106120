.quiz {
	// height: calc(100vh - 120px);
	// background: inherit;
	// padding-top: 0;
	header {
		@include header-leaderboard;
		position: fixed;
	}
	// background: rgba(0, 0, 0, 0.6);
	// padding-top: 90px;
	// background: conic-gradient(
	// 	from 0deg at 50% 50%,
	// 	#ffe710 0deg,
	// 	#ffbd20 360deg
	// );
	.questions {
		background: $white;
		width: 80%;
		margin: 0 auto;
		border-radius: 20px;
		padding: 20px 10px;
		margin-bottom: 100px;
		li {
			list-style-type: none;
			text-align: left;
			label {
				@include btn-primary;
				height: auto;
				width: auto;
				margin-left: 5px;
				font-family: Rubik;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 140%;
				padding: 10px;
				//border: 1px solid $grey-3;
				cursor: pointer;
				z-index: 90;
				margin: auto;
				width: 200px;
				margin-bottom: 10px;
				//background: linear-gradient(
					//268.23deg,
					//$highlight-color-1 0%,
					//$highlight-color-2 100%
				//);
				background: linear-gradient(
					268.23deg,
					var(--response_gradient_color_one) 0%,
					var(--response_gradient_color_two) 100%
				);
				color: var(--response_text_color);
				border-radius: 99px;
				text-align: center;
			}
			input[type="radio"] {
				opacity: 0.01;
				z-index: 100;
				display: none;
			}
		}
		.question {
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			letter-spacing: 0.08em;
			color: $grey-2;
			margin: 5px 28px 0;
		}
		.q-description {
			min-height: 67px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 10px 8px 20px 8px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 140%;
			color: $black-1;
			opacity: 0.9;
		}
	}

	.card-two {
		padding-bottom: 25px;
		margin: 80px 40px 20px 40px;
		.btm-featured {
			display: none;
		}
	}
	.popup {
		background: $white;
		border-radius: 25px;
		max-width: $desktop-modal-width;
		position: absolute;
		width: 80%;
		transform: translate(-50%);
		left: 50%;
		padding: 20px;
		top: 30%;
		height: 250px;
		box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
		h2 {
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			text-align: center;

			/* Акц */

			/*color: $activity-right-bkg;*/
			color: var(--background);
		}

		.popup-inner {
			transform: translate(-50%, -50%);
			left: 50%;
			top: 50%;
			width: 95%;
			position: absolute;
			p {
				font-size: 16px;
				line-height: 19px;
				color: $black-2;
			}
		}
		@media screen and (min-width: 409px) {
			height: 326px;
		}
		@media screen and (min-width: 499px) {
			top: 260px;
			height: 290px;
		}
		button {
			@include btn-primary;
			width: 50%;
			padding: 10px;
			margin: 0 auto;
			height: 35px;
			/*
			background: linear-gradient(
				268.23deg,
				$highlight-color-1 0%,
				$highlight-color-2 100%
			);
			*/
			background: linear-gradient(268.23deg, var(--response_gradient_color_two) 0%, var(--response_gradient_color_one) 100%);
			border-radius: 99px;
		}
	}
	footer {
		li {
			opacity: 0.3;
		}
	}
}
