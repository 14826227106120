@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import "./variables";
@import "./Intro";
@import "./Score";
@import "./Profile";
@import "./ProfilePage";
@import "./Board";
@import "./Video";
@import "./HomeButton";
@import "./Downloader";
@import "./MemoryGame";
@import "./Popup";
@import "./Footer";
@import "./FullDashboard";
@import "./Badges";
@import "./HeaderGreetings";
@import "./TaskCard";
@import "./Timer";
@import "./SignUp";
@import "./Quiz";
@import "./Qr";
@import "./Activities";
@import "./DashBoard";
@import "./Packets";
@import "./Activity";
@import "./Admin";
@import "./Bio";
@import "./BusinessCard";
@import "./ProgressBar";
@import "./ProgressBar2";
@import "./Rewards";
@import "./AirdropsList";

html {
	// height: 100%;

	body {
		/* ARGIT */
		background: var(--background);
		/* url("../assets/bg-overlay.png")*/
		;
		background-size: cover;
		background-attachment: fixed;
		background-position-x: left;
		margin: 0;
		padding: 0;
		// width: 100% !important;
		overflow-x: hidden !important;
	}

	#root {
		overflow: hidden;
	}

	.mobile-wrapper {
		@media (min-width: $desktop-width) {
			max-width: $desktop-width;
			overflow: hidden;
		}
		margin: 0 auto;
	}

	.loader {
		// background: var(--loader_background); //url("../assets/bg-1.jpg");
		background: #000;
		background-size: cover;
		//background-attachment: fixed;
		//background-position-x: left;
		z-index: 9999999;
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;

		&.loaded {
			animation: loader-animation 0.5s forwards;
		}

		/* Spinner Wave */
		.sp-wave {
			border-radius: 50%;
			height: 50px;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			width: 50px;
			position: relative;
			opacity: 1;
		}

		.sp-wave:before,
		.sp-wave:after {
			content: '';
			border: 1px #fff solid;
			border-radius: 50%;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0px;
		}

		.sp-wave:before {
			transform: scale(1, 1);
			opacity: 1;
			-webkit-animation: spWaveBe 0.6s infinite linear;
			animation: spWaveBe 0.6s infinite linear;
		}

		.sp-wave:after {
			transform: scale(0, 0);
			opacity: 0;
			-webkit-animation: spWaveAf 0.6s infinite linear;
			animation: spWaveAf 0.6s infinite linear;
		}

		@-webkit-keyframes spWaveAf {
			from {
				-webkit-transform: scale(0.5, 0.5);
				opacity: 0;
			}

			to {
				-webkit-transform: scale(1, 1);
				opacity: 1;
			}
		}

		@keyframes spWaveAf {
			from {
				transform: scale(0.5, 0.5);
				opacity: 0;
			}

			to {
				transform: scale(1, 1);
				opacity: 1;
			}
		}

		@-webkit-keyframes spWaveBe {
			from {
				-webkit-transform: scale(1, 1);
				opacity: 1;
			}

			to {
				-webkit-transform: scale(1.5, 1.5);
				opacity: 0;
			}
		}

		@keyframes spWaveBe {
			from {
				-webkit-transform: scale(1, 1);
				opacity: 1;
			}

			to {
				-webkit-transform: scale(1.5, 1.5);
				opacity: 0;
			}
		}



		.lagoon-loader-logo {
			position: absolute;
			left: 50%;
			height: 200px;
			top: 40%;
			transform: translate(-50%, -50%);
			animation: logo-animation 5s forwards;
		}
	}

	//.bg-overlay {
	//position: fixed;
	//z-index: -1;
	//top: 0;
	//left: 0;
	///* ARGIT */
	//background: rgba(0, 0, 0, 0.35);
	//width: 100%;
	//height: 100%;
	//}

	#root {
		// width: 100%;
		// height: 100%;
	}
}

@keyframes loader-animation {
	0% {
		visibility: visible;
		//display: block;
		opacity: 1;
		z-index: 99999999;
		background-position: 10% 0%;
	}

	80% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		z-index: -1;
		//visibility: hidden;
		//z-index: -1;
		background-position: 28% 0%;
		//display: none;
	}
}

@keyframes logo-animation {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.ar {
	height: 100vh;

	header {
		@include header-leaderboard;
		position: fixed;
	}

	.ar-wrapper {
		height: 100vh;
		width: 100vw;
		max-width: $desktop-width;
		background: #000;

		.ar-scene-wrap {
			height: 280px;
			width: 280px;
			border-radius: 50%;
			position: relative;
			overflow: hidden !important;
			left: 50%;
			top: 40%;
			transform: translate(-50%, -50%);

			video {
				display: none !important;
				background-color: #000;
				// height: 290px !important;
				// width: 280px !important;
				left: 50% !important;
				top: 40% !important;
				position: absolute;
				// opacity: 1;
				animation: the-end 8s;
				animation-fill-mode: forwards;
				// border-radius: 50%;
				transform: translate(-50%, -50%);
			}
		}

		.face-id-overlay {
			left: 50%;
			top: 40%;
			z-index: 10;
			position: absolute;
			transform: translate(-50%, -50%);

			.finalized {
				background-color: var(--buttons_background);
				opacity: 0;
				width: 290px;
				height: 290px;
				position: absolute;
				z-index: -1;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				border-radius: 50%;
				animation: the-finalized 8.5s;
				animation-fill-mode: forwards;
				border: 20px solid rgba(255, 255, 255, 0.1);

			}

			.button {
				z-index: 1000;
				display: block;
				line-height: 70px;
				font-size: 2em;
				position: absolute;
				font-family: "Rubik";
				text-align: center;
				border-radius: 50%;
				bottom: 7px;
				opacity: 0;
				width: 330px;
				line-height: 450px;
				height: 330px;
				color: #ffffff;
				background: transparent;
				left: 50%;
				transform: translateX(-50%);
				transform-origin: center center;
				box-shadow: transparent;
				transition: 0.2s all;

				&:hover {
					// background: $footer-first;
				}

				&.ready {
					animation: opacity0100 1s forwards;
				}
			}

			svg {
				height: 340px !important;
				width: 340px !important;

				#loading line {
					animation: the-end 8.5s;
					animation-fill-mode: forwards;
				}

				#face {
					// transform: scaleY(1.2);
					transform-origin: center;

					* {
						stroke: #fff;
					}
				}
			}

			#face-id-set-up {
				// animation: the-end 5s;
				animation-fill-mode: forwards;
			}

			#loading {}

			#face {
				animation: face-move 6.8s .5s;
				transform-origin: center center;
			}

			#eyes {
				animation: eyes-blink 6s 1.5s infinite;
				transform-origin: center center;
			}

			#nose {
				animation: nose-move 7s .5s;
				transform-origin: 15% 15% .5%;
			}

			#mouth {
				animation: mouth-move 7s .5s;
			}

			@keyframes face-move {
				15% {
					transform: rotateY(25deg);
				}

				30% {
					transform: rotateY(25deg) rotateX(25deg);
				}

				55% {
					transform: rotateY(-25deg) rotateX(25deg);
				}

				75% {
					transform: rotateY(-25deg) rotateX(-25deg);
				}

				100% {
					transform: rotateY(25deg) rotateX(-25deg);
				}
			}

			@keyframes nose-move {
				15% {
					transform: rotateY(15deg) translateX(6px);
				}

				30% {
					transform: rotateY(15deg) rotateX(15deg) translateX(1px);
				}

				55% {
					transform: rotateY(-15deg) rotateX(15deg);
				}

				75% {
					transform: rotateY(-15deg) rotateX(5deg) translateY(4px);
				}

				100% {
					transform: rotateY(10deg) rotateX(10deg) translateX(2px) translateY(5px);
				}
			}

			@keyframes mouth-move {
				15% {
					transform: rotateY(10deg);
				}

				30% {
					transform: rotateY(10deg) rotateX(10deg);
				}

				55% {
					transform: rotateY(-10deg) rotateX(10deg);
				}

				75% {
					transform: rotateY(-10deg) rotateX(5deg) translateX(2px) translateY(2px);
				}

				100% {
					transform: rotateY(10deg) rotateX(10deg) translateX(-3px) translateY(3px);
				}
			}

			@keyframes eyes-blink {
				5% {
					transform: scaleY(0.05);
				}

				6.5% {
					transform: scaleY(1);
				}
			}

			@keyframes the-end {
				95% {
					opacity: 1;
				}

				100% {
					opacity: 0;
				}
			}

			@keyframes the-finalized {
				95% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}
		}
	}

	.mindar-ui-loading .loader {
		border: 16px solid #222;
		border-top: 16px solid $white;
		opacity: 1;
		top: 50%;
		transform: translate(-50%, -50%);
		left: 50%;
	}

	.generating-text {
		color: $white;
		z-index: 10000;
		font-family: "Poppins";
		padding: 20px 0;
		position: absolute;
		width: 100%;
		bottom: 25%;
		font-size: 20px;
		max-width: $desktop-width;
		// background: $activity-card-bkg;

		&.expanded {
			animation: 1s scale-down forwards;
		}
	}

	.visible.popup {
		height: 560px;
		z-index: 10001;
		max-width: $desktop-modal-width;
		top: 10%;

		img {
			max-width: 280px;
			width: 100%;
		}

		.popup .popup-inner {
			width: 80%;
		}

		button {
			margin-top: 20px;
		}
	}

	.button {
		z-index: 10000;
		display: block;
		line-height: 70px;
		font-size: 2em;
		position: fixed;
		font-family: "Rubik";
		text-align: center;
		border-radius: 25px;
		bottom: 100px;
		width: 200px;
		height: 70px;
		color: $white;
		background: $activity-card-bkg;
		left: 50%;
		transform: scale(0) translateX(-50%);
		box-shadow: 0px 8px 36px var(--buttons_background);
		transition: 0.2s all;

		&:hover {
			background: $footer-first;
		}

		&.ready {
			animation: grow 1s forwards;
		}
	}

	img.bg {
		height: 100vh;
		width: auto;
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
	}

	.bg {
		background-color: #f48fb1;
		height: 100vh;
		width: 100vw;
		position: absolute;
		z-index: 0;
		left: 0;
		top: 0;
		animation: 20s color-change infinite;
	}

	.circle {
		position: absolute;
		z-index: 0;
		width: 150vw;
		height: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
}

#aryel {
	background: black;

	.aryel {
		width: 100%;
		height: 100vh;
		border: 0 !important;
	}

	.visible.popup {
		height: 450px;
		max-width: $desktop-modal-width;
		z-index: 10001;
		top: 20%;

		img {
			max-width: 280px;
			width: 100%;
		}

		.popup .popup-inner {
			width: 80%;
		}

		button {
			margin-top: 20px;
		}
	}

	.button {
		z-index: 10000;
		display: block;
		line-height: 70px;
		font-size: 2em;
		position: fixed;
		font-family: "Rubik";
		text-align: center;
		border-radius: 25px;
		bottom: 100px;
		width: 200px;
		height: 70px;
		color: $white;
		background: $activity-card-bkg;
		left: 50%;
		transform: scale(0) translateX(-50%);
		box-shadow: $activity-card-shadow;
		transition: 0.2s all;

		&:hover {
			background: $footer-first;
		}

		&.ready {
			animation: grow 1s forwards;
		}
	}

	#request {
		position: absolute;
		visibility: hidden;
		bottom: 20px;
		left: 50%;
		transform: translateX(-50%);
	}
}

@keyframes grow {
	0% {
		transform: scale(0) translateX(-50%);
	}

	100% {
		transform: scale(1) translateX(-50%);
	}
}

@keyframes opacity0100 {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes scale-down {
	0% {
		height: 70px;
	}

	100% {
		height: 100vh;
	}
}

@keyframes color-change {
	0% {
		background-color: #f48fb1;
	}

	10% {
		background-color: #880e4f;
	}

	20% {
		background-color: #29b6f6;
	}

	30% {
		background-color: #00e5ff;
	}

	40% {
		background-color: #ab47bc;
	}

	50% {
		background-color: #66bb6a;
	}

	60% {
		background-color: #b2ff59;
	}

	70% {
		background-color: #fdd835;
	}

	80% {
		background-color: #304ffe;
	}

	90% {
		background-color: #ff6f00;
	}

	100% {
		background-color: #ff8a65;
	}
}