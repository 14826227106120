.card-two {
	/*
	background: url(../assets/Group6.png) no-repeat right top,
		url(../assets/Group7.png) left bottom no-repeat, $highlight-color-2;
	*/
	background: rgba(0,0,0,0.15);
	border-radius: 20px;
	margin: 0 24px 24px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.top-featured {
		padding: 24px 0;
		display: block;
		p {
			//position: absolute;
			left: 124px;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			margin: 16px 60px;
			display: flex;
			align-items: center;
			letter-spacing: 0.08em;
			color: $white;

			opacity: 0.7;
			text-transform: uppercase;
		}
		img {
			max-height: 170px;
			border-radius: 0;
			border-radius: 10px;
			margin-top: -120px;
		}
	}
	a {
		// @include links;
		padding: 0;
		margin: 0;
		border: none;
		color: $dark-purple;
		font-weight: 500;
	}

	.mid-featured {
		margin: 0 50px 16px;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 135%;
		text-align: center;
		color: $white;
	}

	.btm-featured {
		// margin-bottom: 32px;
		display: flex;
		margin: 32px;
		margin-top: 10px;
		justify-content: center;
		button {
			@include btn-small;
			padding: 12px 16px;
			transition: 0.2s all;

			height: 54px;
			background: #ffffff;
			border-radius: 30px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 140%;
			color: #000;
			border: none;
			padding: 12px 26px;
			min-width: 150px;

			a {
				font-weight: 600 !important;
			}
			svg {
				margin-right: 10px;
			}
			&:hover {
				transform: scale(0.9);
				// background-color: var(--buttons_background);
			}
		}

		.action-button.loading {
			background-color: #000;
			color: #fff;
			pointer-events: none;

			svg {
				display: none;
			}
		}
		img {
			//position: absolute;
			right: 15px;
			bottom: 42px;
		}
	}

	img {
		border-radius: 50%;
	}
}

.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    border-radius: 50%;
    border-top-color: transparent;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}