.qr {
	header {
		@include header-leaderboard;
	}
	height: 90vh;
	overflow: hidden;
	// .top {
	// 	display: flex;
	// 	margin: 20px;
	// }
	#video {
		height: 80%;
	}
	h1.qr-instruction {
		color: $white;
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		margin-top: 60px;
	}
	.qr-extra {
		font-weight: 300;
		color: $white;
		font-family: Rubik;
		font-size: 1.2em;
	}
}
