button.business-card-btn {
	@include btn-small;
	// margin: -25px 0 25px 70px;
	margin-right: 10px;
	height: 30px;
	font-size: 12px;
	pointer-events: auto;
	opacity: 0.6;
}
.back-side {
	.popup {
		height: 100%;
		width: 100%;
		max-width: $desktop-modal-width;
		font-family: "Rubik";
		color: $white;
		top: 0 !important;
		img {
			border-radius: 50%;
			margin-top: 100px;
			max-width: 270px;
		}
		.names {
			font-size: 2em;
			margin-top: 40px;
		}
		.module-closer {
			display: none;
		}
		.card-name {
			display: flex;
			align-items: center;
			margin-top: 20px;
			font-weight: bold;
		}
		.card-email {
			margin-top: 10px;
			font-size: 18px;
			p {
				font-style: italic;
				margin: 5px;
			}
		}
		.card-company {
			margin-top: 10px;
			font-size: 18px;
			p {
				font-style: italic;
				margin: 5px;
			}
		}
		.show-details {
			height: 30px;
			margin-top: 50px;
		}
		.highlight {
			color: $grey-2 !important;
			font-weight: 100 !important;
		}
	}
}
.header-greetings {
	pointer-events: none;
}
