// DESKTOP WIDTH
$desktop-width: 480px;
// $desktop-height: 700px;
$desktop-modal-width: 420px;

// BACKGROUNDS, COLORS
$bkg-intro: rgb(151, 151, 235);
$bkg-dashboard: #8313df;
$bkg-memoryGame: #560599;
$bkg-video: #8e41ce;
$bkg-downloadPDF: #8246b4;
$link-color: #271963;
$link-border: #280c6b;
$li-color: #1f314a;
$btn-color: #4605f5;
$btn-hover: #4e4ef7;
$btn-facebook: #0056b3;
$dark-purple: #41295a;
$dark-blue: #0d0b1d;
$dull-lavender: #ffd6dd;
$pink-1: #e43aff;
$pink-salmon: #ff8fa2;
$pastel-pink: #ffd6dd;
$hawkes-blue: #ffd6dd;
$mint-tulip: #c9f2e9;
$solid-black: #000;
$black-1: #0c092a;
$black-2: #0d0b1d;
$grey-1: #49465f;
$grey-2: #858494;
$grey-3: #cccccc;
$grey-4: #e6e6e6;
$grey-5: #efeefc;
$grey-6: #747085;
$white: #ffffff;
$red: #ff0000;
$red-2: #ff3a3a;
$orange: #ff9900;
$green-check-mark: #32ff47;
$required-message: #ffe710;
$module-background: #fffcf4;
$activity-card-bkg: $black-2 url("../assets/bg-overlay.png"); //#541d94;
$activity-card-shadow: 0px 8px 36px rgba(84, 29, 148, 0.5);
$activity-points-color: rgba(255, 255, 255, 0.6);
$comleted-activity-mark-bkg: rgba(255, 255, 255, 0.1);
$activity-right-bkg: #b63fff;
$activity-disabled-lock-bkg: linear-gradient(
	114.2deg,
	rgba(117, 73, 174, 0.1) 15.5%,
	rgba(93, 37, 166, 0.1) 93.71%
);
$activity-disabled-lock-shadow: inset 0px -2.75px 2.5px rgba(138, 56, 244, 0.15),
	inset 0px 1.5px 2.5px rgba(255, 255, 255, 0.15);

// FOOTER COLORS

$footer-first: #1c163e;
$footer-second: #171032;
$footer-shadow-1: rgba(138, 56, 244, 0.15);
$footer-shadow-2: rgba(255, 255, 255, 0.15);
$footer-bkg: linear-gradient(
	95.46deg,
	$footer-first 41.4%,
	$footer-second 60.9%
);
$footer-bkg-around: linear-gradient(
	95.46deg,
	$footer-first 41.4%,
	$footer-second 60.9%
);
$highlight-color-1: #e337ff;
$highlight-color-2: #882aff;
$highlight-color: linear-gradient(
	180deg,
	$highlight-color-1 0%,
	$highlight-color-2 100%
);

$score-bkg: linear-gradient(
	180deg,
	rgba(255, 255, 255, 0.1) 0%,
	rgba(255, 255, 255, 0.5) 46.63%,
	rgba(255, 255, 255, 0.1) 100%
);

$score-color: #ff9900;
$placeholder-color: rgba(255, 255, 255, 0.6);

@mixin btn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 133px;
	width: 327px;
	height: 56px;
	border-radius: 20px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
}

@mixin btn-small {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 12px 20px;
	height: 44px;
	background: $white;
	border-radius: 20px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 140%;
	color: $dark-purple;
	border: none;
}

@mixin btn-primary {
	@include btn;
	background-color: $dark-purple;
	color: $white;
}

@mixin btn-secondary {
	@include btn;
	background-color: $grey-5;
	color: $dark-purple;
}

@mixin btn-tertiary {
	@include btn;
	background-color: $white;
	color: $dark-purple;
	border: none;
}

@mixin btn-google {
	@include btn;
	background-color: $white;
	border: 2px solid $grey-4;
	box-sizing: border-box;
	color: $black;
}

@mixin btn-facebook {
	@include btn;
	background-color: $btn-facebook;
	color: $white;
}

@mixin header {
	left: 0px;
	top: 0px;
	// position: fixed;
	// width: 100%;
	z-index: 100;
}

@mixin header-leaderboard {
	@include header;
	position: relative;
	padding: 16px 24px 16px 0;
	background-color: rgba(0, 0, 0, 0.3);//$dark-blue;
	width: 100%;
	z-index: 1000;

	@media screen and (min-width: 544px) {
		max-width: $desktop-width;
		left: 50%;
		transform: translateX(-50%);
		padding-left: 0;
		padding-right: 0;
	}

	.top {
		font-family: Rubik;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 150%;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $white;
		a {
			left: 24px;
			display: flex;
			align-self: center;
			position: absolute;
		}
	}
}

@mixin time-light {
	position: absolute;
	width: 28.43px;
	height: 11.09px;
	left: 12.45px;
	top: 5.17px;
	color: $white;
}

@mixin switcher {
	display: flex;
	margin: 16px 24px;
	background: rgb(12, 9, 42, 0.3);
	border-radius: 20px;
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 150%;
	align-items: center;
	text-align: center;
	.active {
		opacity: 1;
		padding: 8px 0px;
		width: 50%;
		color: $white;
		background: $dull-lavender;
		border-radius: 16px;
		margin: 3px;
	}
	.inactive {
		margin: 0 auto;
		color: $grey-4;
		opacity: 0.7;
	}
}

@mixin footer {
	position: fixed;
	display: block;
	width: 100%;
	max-width: $desktop-width;
	bottom: 0;
	height: 70px;
	// background: url(../assets/SVG/Union.svg);
	// background-size: contain;
	// background: linear-gradient(114.2deg, rgba(117, 73, 174, 0.5) 15.5%, rgba(93, 37, 166, 0.22) 93.71%);
	// box-shadow: inset 0px -2.75px 2.5px rgb(138 56 244 / 15%),
	// inset 0px 1.5px 2.5px rgb(255 255 255 / 15%);
	// backdrop-filter: blur(5.5px);
	background: var(--footer_menu_background);//$footer-bkg;
	box-shadow: inset 0px -2.75px 2.5px $footer-shadow-1,
		inset 0px 1.5px 2.5px $footer-shadow-2;
	backdrop-filter: blur(5.5px);
	border-radius: 25px 25px 0px 0px;
	.footer-container {
		position: relative;
		width: 100%;
		height: 137px;
		// background: url(../assets/Vector.svg);
		background-size: cover;
		background-position: center;
		margin-top: 23px;
	}
	#add-post {
		position: absolute;
		transform: translate(-50%);
		left: 50%;
		g {
			position: absolute;
			transform: translate(calc(50% - 24px), calc(50% - 27px));
		}
		@media screen and (min-width: $medium) {
			width: 115px;
			height: 115px;
		}
		@media screen and (min-width: $large) {
			width: 150px;
			height: 150px;
			bottom: 15px;
		}
	}
	ul {
		list-style: none;
		list-style: none;
		padding: 0px;
		/* margin: 0; */
		display: flex;
		flex-grow: 1;
		justify-content: space-evenly;
		align-items: center;
		width: 100%;
		height: 70px;
		/* position: absolute; */
		/* bottom: 12px;
    /* position: absolute; */
		/* bottom: 12px; */
		// li {
		// 	width: 20%;
		// 	text-align: center;

		// 	&:nth-child(2) {
		// 		margin-right: 7.5%;
		// 	}
		// 	&:nth-child(3) {
		// 		margin-left: 7.5%;
		// 	}
		// 	svg {
		// 		margin: 0 auto;
		// 	}
		// 	button {
		// 		border: none;
		// 		background: $white;
		// 		margin: 0;
		// 		padding: 0;
		// 	}
		// }
		a {
			li {
				position: relative;
				padding-bottom: 30px;
				width: 70px;
				&::after {
					content: "";
					position: absolute;
					width: 51px;
					height: 5px;
					bottom: 16px;
					left: 0;
					background: var(--buttons_background);//$highlight-color;
					border-radius: 5px 5px 0px 0px;
					visibility: hidden;
					-webkit-transform: scaleX(0);
					transform: scaleX(0);
					-webkit-transition: all 0.3s ease-in-out 0s;
					transition: all 0.3s ease-in-out 0s;
				}
			}
			&.active {
				li {
					&::after {
						visibility: visible;
						transform: translate(-50%);
						left: 50%;
					}
					svg {
						path {
							fill: $white;
						}
					}
				}
			}
			&[href="#/qr"] {
				padding: 0px 0 15px 0px;
			}
		}
	}

	#footer-bg {
		display: none;
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 137px;
	}
}

// QUIRES:
$medium: 455px;
$large: 600px;
// BUTTONS
// Dashboard links
@mixin names {
	font-family: $li-font;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	line-height: 35px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	color: $li-color;
	opacity: 0.9;
	letter-spacing: -0.0165px;
	text-align: left;
}

@mixin links {
	min-width: 140px;
	padding: 2px 20px;
	margin: 7px auto;
	text-decoration: none;
	border-radius: 20px 20px 20px 20px;
	border: 2px solid $link-border;
	background-color: $white;
	font-family: $db-font;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 1.15px;
	color: $link-color;
	&:hover {
		color: #000;
		background: rgb(0, 216, 214);
		background: linear-gradient(
			160deg,
			rgb(135, 80, 223) 0%,
			rgba(255, 255, 255, 1) 35%,
			rgb(59, 14, 117) 100%
		);
		transform: scale(1.05, 1.05);
	}
}

@mixin button {
	cursor: pointer;
	margin-top: 10px;
	width: 150px;
	height: 34px;
	background: $btn-color;
	border-radius: 100px;
	font-family: $li-font;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	line-height: 22px;
	text-align: center;
	color: $white;
	transition-duration: 0.4s;
	&:hover {
		background-color: $btn-hover;
		transform: translateY(1px);
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	}
	&:active {
		transform: scale(0.95, 0.95);
	}
}

@mixin invisible {
	display: none;
}

@mixin label {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 150%;
	display: flex;
	align-items: center;
	text-align: center;
	color: $white;
}

@mixin heading-1 {
	font-family: Rubik;
	font-style: normal;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: $pastel-pink;
}

// MARGINS

// FONTS
$bold-font: "CaustenRound-Bold";
$db-font: "Poppins";
$li-font: "Roboto";
