.dashboard {
	.disabled-project {
		font-size: 25px;
		margin-top: 100px;
		color: #fff;
	}
	.profile-titles,
	.wallet {
		display: none;
	}
	.badges {
		padding-top: 35px;
	}
	.header-greetings {
		.greetings {
			// animation: tobigger 0.3s forwards;
			padding-top: 110px;
		}
	}
	.packets {
		top: 0;
		position: inherit;
		header {
			display: none;
		}
		.activities {
			.wallet {
				display: block;
			}
		}
		.card-three {
			&.disabled {
				display: none;
			}
			&.completed {
			}
		}
		.no-activity {
			display: block;
			position: absolute;
			background: $module-background;
			border-radius: 25px 25px 0px 0px;
			padding-top: 40px;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 24px;
			text-align: center;
			color: $black-2;
			height: 300px;
			z-index: -1;
			width: 100%;
			.qr-no-activity {
				color: $activity-right-bkg;
			}
		}
	}
	.profile {
		margin-bottom: 0;
		button {
			display: none;
		}
		.applewalletcard {
			display: none;
		}
	}

	.card-three {
		padding-bottom: 5px !important;
		a {
			text-decoration: none;
			button {
				&.business-card-btn {
					&.my-collectables {
						visibility: visible;
						display: block;
						margin: 5px auto;
						padding: 0;
						height: 30px;
						opacity: 1;
					}
				}
			}
		}
		.wallet {
			display: flex;
			margin: 10px auto;
			justify-content: center;
			overflow: visible !important;
			border-radius: 20px !important;
			p {
				display: none;
			}
			.visible.popup {
				top: 25%;
				position: fixed;
				padding-top: 40px;
				background: var(--activity_background);
			}
			.popup .emoji {
				font-size: 130px;
				margin-bottom: 20px;
				opacity: 0.8;
				width: auto !important;
				margin-left: 0;
			}
			.popup .popup-inner {
				position: relative;
			}
			.popup .wallet-image {
				margin-left: 0;
				width: 150px !important;
				height: auto;
				border-radius: 20px;
			}
			.popup .video-wrapper {
				display: none;
			}
			.popup .popup-text,
			.popup div,
			.popup p {
				color: #fff;
			}
			.popup button {
				margin-bottom: 0 !important;
			}
			.popup .popup-inner .wallet-card {
				margin: 0 auto 20px;
				width: 100%;
				padding: 0;
				// border-radius: 20px;
				background: transparent;
				height: fit-content;
				.emoji {
					line-height: 150px;
				}
			}
			.wallet-flex {
				display: flex;
				width: 100%;
				justify-content: space-evenly;

				// .wallet-card.activity-nft {
				// 	&
				// }
				.emoji {
					font-size: 35px;
				}
				#new-avatar {
					img {
						max-width: 45px;
					}
				}
			}

			.wallet-flex > :nth-child(n+5) { 
				display: none;
			}
			pointer-events: all;
		}
	}
	footer {
		li:not(:first-child) {
			opacity: 0.3;
		}
	}
	.projects {
		font-family: "Rubik";
		color: #fff;
		h2 {
			margin-bottom: 30px;
		}
		div {
			margin-bottom: 10px;
			a {
				color: #fff;
				text-decoration: none;
			}
		}
	}
}
