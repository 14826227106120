body {
	// background-color: #f2f2f2;
	text-align: center;
}

.intro {
	h1 {
		@include heading-1;
	}
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		label {
			@include label;
		}
		input {
			width: 315px;
			height: 44px;
			font-size: 22px;
			text-align: center;
			margin: 7px;
			background: rgba(12, 9, 42, 0.16);
			border-radius: 20px;
			color: $grey-4;
			font-family: Rubik;
			font-style: normal;
			font-weight: normal;
			line-height: 150%;
			display: flex;
			align-items: center;
			&::placeholder {
				// background: rgba(12, 9, 42, 0.16);
				font-family: Rubik;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 150%;
				display: flex;
				align-items: center;
				color: $grey-4;
			}
		}
	}
	button {
		@include btn-tertiary;
		padding: 0;
		margin-top: 10px;
		width: 150px;
		height: 34px;
		border: 1.5px solid rgba(106, 90, 224, 0.2);
		&:hover {
			// background-color: $btn-hover;
			transform: translateY(1px);
			box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
		}
		&:active {
			transform: scale(0.95, 0.95);
		}
	}
}
