@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap);
/* purgecss start ignore */

body{
  text-align:center
}

.intro h1{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  line-height:150%;
  letter-spacing:.04em;
  text-transform:uppercase;
  color:#ffd6dd
}

.intro form{
  display:flex;
  flex-direction:column;
  align-items:center
}

.intro form label{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  text-align:center;
  color:#fff
}

.intro form input{
  width:315px;
  height:44px;
  font-size:22px;
  text-align:center;
  margin:7px;
  background:rgba(12,9,42,.16);
  border-radius:20px;
  color:#e6e6e6;
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  line-height:150%;
  display:flex;
  align-items:center
}

.intro form input::placeholder{
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:16px;
  line-height:150%;
  display:flex;
  align-items:center;
  color:#e6e6e6
}

.intro button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#fff;
  color:#41295a;
  border:none;
  padding:0;
  margin-top:10px;
  width:150px;
  height:34px;
  border:1.5px solid rgba(106,90,224,.2)
}

.intro button:hover{
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.intro button:active{
  transform:scale(0.95, 0.95)
}

.ranks{
  margin:0px 22px 16px;
  padding:9px 0;
  border-radius:20px;
  display:flex;
  justify-content:space-around
}

.ranks #points,.ranks #world-rank,.ranks #local-rank{
  padding:22px;
  width:43%;
  background:linear-gradient(114.2deg, rgba(117, 73, 174, 0.1) 15.5%, rgba(93, 37, 166, 0.1) 93.71%);
  box-shadow:inset 0px -2.75px 2.5px rgba(138,138,138,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  margin:0;
  border-radius:25px;
  display:flex;
  justify-content:space-between
}

.ranks #local-rank .logo{
  width:55px;
  height:61px
}

.ranks #local-rank .inside-ranks .value{
  width:36px;
  height:30px
}

.ranks #local-rank .inside-ranks .value.top{
  background-color:#32ff47;
  color:#0c092a
}

.ranks #local-rank .inside-ranks .value.low{
  background-color:#ff3a3a
}

.ranks #local-rank .inside-ranks .value.middle{
  background-color:#e43aff
}

.ranks #points{
  margin-right:12px
}

.ranks #points .stars{
  display:flex;
  flex-direction:column;
  width:50px
}

.ranks #points .stars img:first-of-type{
  width:28px;
  height:auto
}

.ranks #points .stars img:nth-of-type(2){
  align-self:flex-end
}

.ranks #points .stars img:last-of-type{
  width:13px;
  height:auto
}

.ranks .logo{
  width:22px;
  height:22px
}

.ranks .border{
  background:linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.5) 46.63%, rgba(255, 255, 255, 0.1) 100%);
  width:2px
}

.ranks .text{
  margin-bottom:12px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:#fff
}

.ranks .value{
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:#fff;
  text-align:center;
  height:30px;
  width:31px;
  justify-content:center;
  display:flex;
  align-items:center;
  background:#f90;
  border-radius:50%
}

.profile{
  background:transparent;
  padding:0px 0 10px
}

.profile .hide-details{
  display:none
}

.profile .profile-titles,.profile .home-titles{
  margin:0;
  align-items:center;
  text-align:left;
  padding-left:20px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  color:#fff
}

.profile .profile-titles.mb-11,.profile .home-titles.mb-11{
  margin-bottom:11px
}

.profile .wallet{
  border-radius:25px;
  padding:10px 0 20px 0;
  padding-bottom:0px
}

.profile .wallet .wallet-button{
  display:none !important
}

.profile .wallet .profile-titles{
  color:#000
}

.profile .wallet .wallet-flex{
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  padding:10px 20px
}

.profile .wallet .wallet-flex .wallet-card.activity-nft::after{
  opacity:.05
}

.profile .wallet .wallet-flex .wallet-card.activity-nft.earned-reward{
  background-size:contain;
  background-color:var(--nft_color_three);
  background-position:center
}

.profile .wallet .wallet-flex .wallet-card.activity-nft.earned-reward .reward-name{
  font-size:1.1em !important;
  line-height:1.1em !important
}

.profile .wallet .wallet-flex .wallet-card.activity-nft.earned-reward::after{
  opacity:.4
}

.profile .wallet .wallet-card{
  width:47%;
  max-width:205px;
  padding-top:0;
  height:auto;
  margin-bottom:20px;
  background:var(--buttons_background);
  box-shadow:inset 0px -2.75px 2.5px rgba(138,56,244,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px
}

.profile .wallet .wallet-card .wallet-image{
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px;
  width:100%;
  height:100%;
  background-color:#fff
}

.profile .wallet .wallet-card#voucher p{
  display:flex;
  justify-content:center;
  align-items:center;
  margin-bottom:12px;
  font-family:"Rubik";
  font-style:italic;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:#fff
}

.profile .wallet .wallet-card#voucher p:first-of-type{
  font-size:16px;
  color:#fff;
  font-weight:700;
  font-style:normal;
  margin:30px auto 20px
}

.profile .wallet .wallet-card#voucher button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  width:65%;
  padding:10px 20px;
  height:40px;
  border-radius:99px;
  margin:0 auto
}

.profile .wallet .wallet-card#voucher .emoji{
  font-size:35px;
  margin-bottom:20px
}

.profile .wallet .wallet-card.activity-nft{
  overflow:hidden;
  position:relative;
  min-height:160px
}

.profile .wallet .wallet-card.activity-nft p{
  color:var(--buttons_color);
  font-size:1.1em;
  line-height:1.1em;
  font-weight:bold;
  font-family:"Rubik";
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  position:absolute;
  z-index:10;
  margin:0
}

.profile .wallet .wallet-card.activity-nft::after{
  position:absolute;
  content:"";
  left:0;
  top:0;
  width:100%;
  height:100%;
  opacity:.25;
  background:#000
}

.profile .wallet .wallet-card.activity-nft.download{
  background:var(--nft_color_one)
}

.profile .wallet .wallet-card.activity-nft.video{
  background:var(--nft_color_three)
}

.profile .wallet .wallet-card.activity-nft.avatar{
  background-size:cover
}

.profile .wallet .wallet-card.activity-nft.avatar::after{
  opacity:0
}

.profile .wallet .wallet-card.activity-nft.bio{
  display:none;
  background:var(--nft_color_two)
}

.profile .wallet .wallet-card.activity-nft.bio .emoji{
  opacity:.4 !important;
  font-size:140px
}

.profile .wallet .wallet-card.activity-nft.bio::after{
  opacity:.5
}

.profile .wallet .wallet-card.activity-nft.reward img.emoji{
  opacity:.1 !important
}

.profile .wallet .wallet-card.activity-nft .reward-name{
  font-size:1.6em;
  line-height:1.05em
}

.profile .wallet .wallet-card.activity-nft .emoji{
  font-size:100px;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  position:absolute;
  margin-bottom:20px;
  opacity:.3
}

.profile .wallet .wallet-card.activity-nft#bio p{
  font-size:5vw;
  text-transform:capitalize
}

.profile .ranks{
  display:none
}

.profile .links{
  margin:35px 0;
  width:100%;
  display:flex;
  flex-direction:column
}

.profile .links a{
  padding:5px;
  min-width:140px;
  padding:2px 20px;
  margin:7px auto;
  text-decoration:none;
  border-radius:20px 20px 20px 20px;
  border:2px solid #280c6b;
  background-color:#fff;
  font-family:"Poppins";
  font-style:normal;
  font-weight:600;
  font-size:16px;
  line-height:24px;
  letter-spacing:1.15px;
  color:#271963
}

.profile .links a:hover{
  color:#000;
  background:#00d8d6;
  background:linear-gradient(160deg, #8750df 0%, white 35%, #3b0e75 100%);
  transform:scale(1.05, 1.05)
}

.profile .links a:visited{
  color:#271963
}

.profile .card-three{
  background:#fff;
  border-radius:32px;
  margin:0 0 30px;
  padding:24px
}

@media screen and (min-width: 600px){
  .profile .card-three{
    padding-bottom:60px
  }
}

.profile .card-three .top-three{
  display:flex;
  justify-content:space-between;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:140%;
  color:#0c092a
}

.profile .card-three .top-three a{
  font-size:14px;
  color:#41295a;
  text-decoration:none
}

.profile .card-three .quiz-card{
  border:2px solid #efeefc;
  box-sizing:border-box;
  margin:16px 0px;
  background:#fff;
  border-radius:20px;
  display:flex;
  justify-content:start;
  align-items:center;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  padding:8px 28px 8px 8px
}

.profile .card-three .quiz-card a{
  display:flex;
  align-items:center;
  width:100%;
  text-decoration:none
}

.profile .card-three .quiz-card .left{
  margin:6px 16px 0 0
}

.profile .card-three .quiz-card .middle .quiz-name{
  margin:0;
  color:#0c092a;
  text-decoration:none
}

.profile .card-three .quiz-card .middle .quiz-description{
  font-weight:normal;
  font-size:12px;
  color:#858494;
  margin:6px 0px
}

.profile .card-three .quiz-card .right{
  margin-left:auto
}

.profile .popup{
  background:var(--background);
  top:20%;
  max-width:420px;
  position:fixed;
  height:460px;
  box-shadow:0px 0px 20px rgba(0,0,0,.4)
}

.profile .popup img{
  width:120%;
  max-width:400px;
  transform:translate(-50%);
  left:50%;
  position:relative
}

.profile .popup .popup-inner h2{
  color:var(--buttons_color);
  font-size:24px
}

.profile .popup .popup-inner .flip-side a[href="#/profile"]{
  text-decoration:none
}

.profile .popup .popup-inner .wallet-card{
  background-color:transparent;
  margin-top:50px
}

.profile .popup .popup-inner .wallet-image{
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px;
  margin-top:0;
  width:110%;
  height:110%
}

.profile .popup button:not(.download-btn,.show-details,.redeem-btn){
  display:none
}

.profile .popup .download-btn,.profile .popup .show-details{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:12px 20px;
  height:44px;
  background:#fff;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  color:#41295a;
  border:none;
  width:170px;
  padding:12px 16px
}

.profile .popup .popup-text,.profile .popup .company-details{
  color:#fff;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:17px;
  line-height:22px
}

.profile .popup .popup-text #company-name,.profile .popup .company-details #company-name{
  font-weight:bold;
  text-decoration:underline;
  text-transform:uppercase
}

.profile .popup .emoji{
  font-size:130px;
  margin-bottom:20px;
  opacity:.8
}

@media screen and (min-width: 409px){
  .profile .popup{
    height:500px
  }
}

@media screen and (min-width: 479px){
  .profile .popup{
    height:600px
  }
}

@media screen and (min-width: 569px){
  .profile .popup{
    height:700px
  }
}

.profile footer li:not(:last-child){
  opacity:.3
}

.profile .wallet-btn-wrapper{
  display:flex;
  margin:-10px 0 25px 25px
}

.profile .wallet-btn-wrapper .applewalletcard{
  width:100px;
  height:auto
}

.profile-page{
  background:var(--rewards_and_wallet_background);
  background-blend-mode:color;
  background-size:cover;
  background-attachment:fixed;
  background-position-x:left;
  height:calc(100vh - 120px);
  padding:1px 0 10px;
  margin-bottom:180px;
  height:100vh;
  overflow:hidden;
  overflow-y:scroll;
  position:fixed;
  width:100%;
  max-width:480px
}

.profile-page header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed;
  z-index:10000;
  max-width:480px;
  margin:0 auto
}

@media screen and (min-width: 544px){
  .profile-page header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.profile-page header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.profile-page header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.profile-page header .top p{
  margin:0
}

.profile-page header .top button{
  background:none;
  border:none;
  position:fixed;
  right:24px;
  top:24px
}

.profile-page header .top button svg path{
  stroke:var(--buttons_background)
}

.profile-page header.header-greetings{
  background:transparent;
  position:relative;
  margin-top:80px;
  z-index:1
}

.profile-page .profile .profile-wrapper{
  padding:18px 0;
  border-radius:25px 25px 0px 0px
}

.profile-page .profile .profile-wrapper .dash{
  border:1px solid rgba(0,0,0,.15);
  width:26px;
  margin:0 auto
}

.profile-page .profile .profile-wrapper p.profile-titles{
  font-size:32px
}

.profile-page .profile .ranks{
  display:flex;
  margin-bottom:30px
}

.profile-page .profile .badge{
  pointer-events:none;
  width:50%;
  opacity:1;
  pointer-events:none
}

.profile-page .profile .badge.hidden-in-wallet{
  display:none
}

.profile-page .profile .badge img{
  max-width:120px
}

.profile-page .logo-wrap,.profile-page .home-titles,.profile-page .progressVisualFull,.profile-page .progressVisualRewards{
  display:none
}

.profile-page .header-greetings{
  height:60px
}

.profile-page .links{
  margin:35px 0;
  width:100%;
  display:flex;
  flex-direction:column
}

.profile-page .links a{
  padding:5px;
  min-width:140px;
  padding:2px 20px;
  margin:7px auto;
  text-decoration:none;
  border-radius:20px 20px 20px 20px;
  border:2px solid #280c6b;
  background-color:#fff;
  font-family:"Poppins";
  font-style:normal;
  font-weight:600;
  font-size:16px;
  line-height:24px;
  letter-spacing:1.15px;
  color:#271963
}

.profile-page .links a:hover{
  color:#000;
  background:#00d8d6;
  background:linear-gradient(160deg, #8750df 0%, white 35%, #3b0e75 100%);
  transform:scale(1.05, 1.05)
}

.profile-page .links a:visited{
  color:#271963
}

.profile-page .popup-inner .wallet-card{
  margin:0 auto 20px;
  width:100%;
  padding:0 0
}

.profile-page .card-three{
  background:#fff;
  border-radius:32px;
  margin:0 0 30px;
  padding:24px
}

@media screen and (min-width: 600px){
  .profile-page .card-three{
    padding-bottom:60px
  }
}

.profile-page .card-three .top-three{
  display:flex;
  justify-content:space-between;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:140%;
  color:#0c092a
}

.profile-page .card-three .top-three a{
  font-size:14px;
  color:#41295a;
  text-decoration:none
}

.profile-page .card-three .quiz-card{
  border:2px solid #efeefc;
  box-sizing:border-box;
  margin:16px 0px;
  background:#fff;
  border-radius:20px;
  display:flex;
  justify-content:start;
  align-items:center;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  padding:8px 28px 8px 8px
}

.profile-page .card-three .quiz-card a{
  display:flex;
  align-items:center;
  width:100%;
  text-decoration:none
}

.profile-page .card-three .quiz-card .left{
  margin:6px 16px 0 0
}

.profile-page .card-three .quiz-card .middle .quiz-name{
  margin:0;
  color:#0c092a;
  text-decoration:none
}

.profile-page .card-three .quiz-card .middle .quiz-description{
  font-weight:normal;
  font-size:12px;
  color:#858494;
  margin:6px 0px
}

.profile-page .card-three .quiz-card .right{
  margin-left:auto
}

.profile-page .logout-btn{
  color:#fff;
  text-align:center;
  position:relative;
  bottom:200px;
  font-family:"Rubik";
  font-size:1.1em
}

.profile-page footer li:not(:last-child){
  opacity:.3
}

.board{
  list-style:none;
  counter-reset:item;
  margin:0 8px;
  background:url(/static/media/Rectangle.e7841c14.svg);
  background-size:cover;
  border-radius:32px;
  padding:8px
}

@media screen and (min-width: 455px){
  .board{
    padding:20px
  }
}

@media screen and (min-width: 600px){
  .board{
    padding:36px
  }
}

.board li{
  counter-increment:item;
  margin-bottom:5px
}

.board li .name-card{
  display:flex;
  background:#fff;
  border-radius:20px;
  margin:16px 0;
  padding:22px 16px;
  text-transform:capitalize;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  align-items:center;
  color:#0c092a
}

.board li:before{
  border:1.5px solid #e6e6e6;
  border-radius:50%;
  color:#858494;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:12px;
  line-height:18px;
  text-align:center;
  padding:4px 9px;
  margin-right:16px;
  width:8px
}

.board li .medal{
  margin-left:auto
}

.board li .main{
  display:flex;
  align-items:center
}

.board li .main .avatar{
  border-radius:50%;
  width:60px;
  margin-right:12px
}

.board li .main .points{
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:14px;
  line-height:140%;
  color:#858494;
  margin:4px 0 0
}

.board li .main .name{
  margin:0
}

.board .board{
  margin:0;
  display:flex;
  flex-direction:column
}

.board .board li:before{
  margin-right:10px;
  content:counter(item);
  background:#add8e6;
  border-radius:100%;
  color:#fff;
  width:1.2em;
  text-align:center;
  display:inline-block
}

.board .board .compid{
  font-weight:normal;
  font-size:14px;
  line-height:14px;
  display:flex;
  align-items:center;
  color:#49465f;
  opacity:.8
}

.video-page{
  height:100%;
  background:rgba(0,0,0,.6);
  padding-top:90px;
  min-height:100vh
}

.video-page header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed;
  z-index:100;
  top:0
}

@media screen and (min-width: 544px){
  .video-page header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.video-page header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.video-page header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.video-page .video-wrapper{
  width:100%;
  height:auto;
  margin:auto;
  position:relative;
  top:0%;
  margin-top:60px;
  height:220px;
  z-index:100
}

.video-page #movie_player{
  padding-top:56.25%;
  position:relative
}

.video-page .video-wrapper>div{
  width:auto !important;
  display:flex;
  justify-content:center
}

.video-page button{
  cursor:pointer;
  margin-top:10px;
  width:150px;
  height:34px;
  background:#4605f5;
  border-radius:100px;
  font-family:"Roboto";
  font-style:normal;
  font-weight:bold;
  font-size:15px;
  line-height:22px;
  text-align:center;
  color:#fff;
  transition-duration:.4s
}

.video-page button:hover{
  background-color:#4e4ef7;
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.video-page button:active{
  transform:scale(0.95, 0.95)
}

.video-page .card-two{
  position:absolute
}

.video-page .card-two .btm-featured{
  display:none
}

.video-page .card-two .top-featured{
  display:none
}

.video-page .skip-btn{
  position:absolute;
  background:var(--buttons_background) !important;
  font-family:"Rubik";
  border:none !important;
  top:80%;
  left:50%;
  z-index:999999;
  pointer-events:all;
  transform:translate(-50%, -50%)
}

.video-page .skip-btn:hover,.video-page .skip-btn:focus{
  transform:translate(-50%, -50%)
}

.video-page footer li{
  opacity:.3
}

.video-page .task-hidden{
  visibility:hidden
}

.home-button{
  cursor:pointer;
  margin-top:10px;
  width:150px;
  height:34px;
  background:#4605f5;
  border-radius:100px;
  font-family:"Roboto";
  font-style:normal;
  font-weight:bold;
  font-size:15px;
  line-height:22px;
  text-align:center;
  color:#fff;
  transition-duration:.4s
}

.home-button:hover{
  background-color:#4e4ef7;
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.home-button:active{
  transform:scale(0.95, 0.95)
}

.downloader{
  height:100%
}

.downloader header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000
}

@media screen and (min-width: 544px){
  .downloader header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.downloader header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.downloader header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.downloader #top-downloader{
  display:flex;
  justify-content:center
}

.downloader #top-downloader .greetings{
  margin-top:30px
}

.downloader #top-downloader .flex{
  margin-top:150px
}

.downloader #top-downloader .flex button{
  margin-top:20px
}

.downloader footer li{
  opacity:.3
}

.memory-game{
  height:calc(100vh - 120px);
  padding:20px 10px 205px;
  padding-bottom:0;
  padding-top:90px
}

.memory-game header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000
}

@media screen and (min-width: 544px){
  .memory-game header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.memory-game header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.memory-game header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.memory-game .card-two{
  margin:24px
}

.memory-game .card-two .mid-featured{
  margin:10px 35px 35px;
  font-size:15px
}

.memory-game .card-two .btm-featured{
  display:none
}

.memory-game img{
  display:flex;
  max-width:500px;
  width:50%;
  height:auto;
  margin:auto
}

.memory-game .memory-foundMatch{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#fff;
  color:#41295a;
  border:none;
  display:inline-block;
  width:170px;
  padding:0
}

.memory-game .timer-wrap{
  display:flex;
  justify-content:end;
  margin:15px 36px 0 0
}

.memory-game .timer-wrap .timer{
  width:100px;
  height:34px;
  background:#5144b6;
  border-radius:12px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:12px;
  line-height:150%;
  color:#e6e6e6;
  display:flex;
  justify-content:space-evenly;
  align-items:center
}

.memory-game .popup-inner{
  border-radius:20px;
  position:absolute;
  transform:translate(-50%, -50%);
  left:50%;
  width:calc(100% - 68px);
  height:155px;
  background-color:#fff
}

.memory-game .popup-inner button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  position:absolute;
  width:130px;
  height:35px;
  padding:0;
  transform:translate(-50%);
  left:50%
}

.memory-game .popup-inner h2{
  margin-top:34px;
  opacity:.7
}

.memory-game footer{
  left:0
}

.memory-game footer li{
  opacity:.3
}

.modalBg{
  width:100%;
  height:auto;
  position:absolute;
  display:flex;
  justify-content:center;
  align-items:center;
  left:0;
  top:212px
}

.modalBg .modalContainer{
  width:80%;
  height:auto;
  background:#0d0b1d;
  color:#fff
}

.modalBg .modalContainer .flex-col{
  flex-direction:column
}

.modalBg .modalContainer .flex-col label{
  margin:15px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  text-align:center;
  color:#fff;
  color:#ffd6dd
}

.modalBg .modalContainer .flex-col input{
  width:225px;
  height:44px;
  left:30px;
  background:#fff;
  box-shadow:0px 12px 12px rgba(52,84,122,.0561594);
  border-radius:100px;
  font-size:22px;
  text-align:center;
  margin:7px
}

.modalBg .modalContainer .titleCloseBtn button{
  position:absolute;
  color:#fff;
  text-align:end;
  width:100%
}

.modalBg .modalContainer .titleCloseBtn button:hover{
  background-color:inherit;
  transform:none;
  box-shadow:none
}

.modalBg .modalContainer title p{
  margin:0
}

.modalBg .timer-wrap{
  justify-content:center;
  margin:15px 0 0
}

.modalBg .foundMatch-submit{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#efeefc;
  color:#41295a;
  width:160px;
  height:45px;
  padding:0
}

.modalBg .popup-inner{
  position:absolute;
  transform:translate(-50%, -100%);
  left:50%;
  width:calc(100% - 83px);
  height:200px;
  background-color:#fff
}

.modalBg .popup-inner button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  position:absolute;
  width:170px;
  height:45px;
  padding:0;
  transform:translate(-50%);
  left:50%
}

.popup{
  background:#fff;
  position:absolute;
  border-radius:20px;
  width:80%;
  max-width:420px;
  transform:translate(-50%);
  transform-origin:center center;
  left:50%;
  top:265px;
  height:331px;
  z-index:999
}

.popup .module-closer{
  position:absolute;
  color:#fff;
  font-size:20px;
  top:10px;
  right:20px;
  transform:scale(0.8);
  transition:.25s all
}

.popup .module-closer:hover,.popup .module-closer:active{
  transform:scale(0.7)
}

.popup p{
  font-family:"Rubik"
}

.popup h2{
  text-align:center;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:#000
}

.popup .popup-inner{
  transform:translate(-50%, -50%);
  left:50%;
  top:45%;
  width:70%;
  position:absolute
}

.popup .popup-inner.rounded p{
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:18px;
  line-height:25px;
  text-align:center;
  color:#0d0b1d
}

@media screen and (min-width: 409px){
  .popup{
    height:326px
  }
}

@media screen and (min-width: 499px){
  .popup{
    top:260px;
    height:290px
  }
}

.popup button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  width:50%;
  padding:10px;
  margin:35px auto 0;
  height:35px;
  background:var(--buttons_background);
  border-radius:99px;
  border:0
}

.popup button.redeem-btn{
  background-color:#fff !important;
  padding:4px 20px !important;
  color:#000 !important
}

.visible.popup{
  animation:showModal .75s forwards;
  animation-timing-function:cubic-bezier(0.2, -2, 0.8, 2)
}

.hidden.popup{
  animation:hideModal .75s forwards;
  animation-timing-function:cubic-bezier(0.2, -2, 0.8, 2)
}

@keyframes showModal{
  0%{
    opacity:0;
    transform:scale(0) translate(-100%)
  }

  100%{
    opacity:1;
    transform:scale(1) translate(-50%)
  }
}

@keyframes hideModal{
  0%{
    opacity:1;
    transform:scale(1) translate(-50%)
  }

  100%{
    opacity:0;
    transform:scale(0) translate(-100%)
  }
}

#error{
  font-size:10px;
  color:red;
  line-height:12px;
  margin-bottom:-20px;
  margin-top:10px
}

input#activation-input{
  padding:10px;
  font-size:16px;
  text-align:center
}

.visible.popup.notification{
  background:var(--loader_background)
}

.popup.notification button{
  background:#fff;
  color:#000;
  margin-top:0px;
  font-size:14px;
  width:auto;
  min-width:150px
}

.popup.notification p,.popup.notification h2{
  color:var(--greeting_text_color) !important
}

.popup.notification h2{
  font-size:14px
}

footer{
  position:fixed;
  display:block;
  width:100%;
  max-width:480px;
  bottom:0;
  height:70px;
  background:var(--footer_menu_background);
  box-shadow:inset 0px -2.75px 2.5px rgba(138,56,244,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:25px 25px 0px 0px;
  z-index:100
}

footer .footer-container{
  position:relative;
  width:100%;
  height:137px;
  background-size:cover;
  background-position:center;
  margin-top:23px
}

footer #add-post{
  position:absolute;
  transform:translate(-50%);
  left:50%
}

footer #add-post g{
  position:absolute;
  transform:translate(calc(50% - 24px), calc(50% - 27px))
}

@media screen and (min-width: 455px){
  footer #add-post{
    width:115px;
    height:115px
  }
}

@media screen and (min-width: 600px){
  footer #add-post{
    width:150px;
    height:150px;
    bottom:15px
  }
}

footer ul{
  list-style:none;
  list-style:none;
  padding:0px;
  display:flex;
  flex-grow:1;
  justify-content:space-evenly;
  align-items:center;
  width:100%;
  height:70px
}

footer ul a li{
  position:relative;
  padding-bottom:30px;
  width:70px
}

footer ul a li::after{
  content:"";
  position:absolute;
  width:51px;
  height:5px;
  bottom:16px;
  left:0;
  background:var(--buttons_background);
  border-radius:5px 5px 0px 0px;
  visibility:hidden;
  transform:scaleX(0);
  transition:all .3s ease-in-out 0s
}

footer ul a.active li::after{
  visibility:visible;
  transform:translate(-50%);
  left:50%
}

footer ul a.active li svg path{
  fill:#fff
}

footer ul a[href="#/qr"]{
  padding:0px 0 15px 0px
}

footer #footer-bg{
  display:none;
  position:absolute;
  bottom:0;
  width:100%;
  height:137px
}

footer .cameraInput{
  visibility:hidden
}

footer .cameraInput #video{
  height:80%
}

footer li.qr-around{
  width:94px;
  height:84px;
  background:var(--footer_menu_background);
  border-radius:50%;
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px)
}

footer li.qr-around svg{
  border-radius:50%;
  background:var(--buttons_background) linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)
}

footer li.qr-around svg path{
  fill:var(--buttons_color)
}

footer #menu-qr{
  position:absolute;
  transform-origin:center;
  left:50%;
  top:50%;
  transform:translate(-50%, -80%)
}

.full-dashboard header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed
}

@media screen and (min-width: 544px){
  .full-dashboard header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.full-dashboard header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.full-dashboard header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.full-dashboard #dot{
  position:absolute;
  transform:translate(calc(50% - 7px), 10px);
  z-index:1
}

.full-dashboard .full-leaderboard{
  background:url(/static/media/Rectangle.e7841c14.svg) no-repeat;
  background-size:100%;
  position:relative;
  overflow-y:hidden;
  border-radius:25px;
  margin:70px 10px 50px;
  padding:15px 15px 20px;
  text-align:left
}

@media screen and (min-width: 455px){
  .full-dashboard .full-leaderboard{
    padding:20px
  }
}

@media screen and (min-width: 600px){
  .full-dashboard .full-leaderboard{
    padding:36px
  }
}

.full-dashboard .full-leaderboard ol{
  counter-reset:item
}

.full-dashboard .full-leaderboard li{
  list-style:none;
  counter-increment:item;
  margin-bottom:5px
}

.full-dashboard .full-leaderboard li:first-child{
  margin-top:16px
}

.full-dashboard .full-leaderboard li:first-child .name-card .main::before{
  content:url(/static/media/gold-medal.02c42205.svg);
  border:none;
  width:auto;
  padding:0
}

.full-dashboard .full-leaderboard li:nth-child(2) .name-card .main::before{
  content:url(/static/media/silver-medal.b393e9d1.svg);
  border:none;
  width:auto;
  padding:0
}

.full-dashboard .full-leaderboard li:nth-child(3) .name-card .main::before{
  content:url(/static/media/bronze-medal.f1d9b543.svg);
  border:none;
  width:auto;
  padding:0
}

.full-dashboard .full-leaderboard .main:before{
  content:counter(item);
  display:inline-block;
  border-radius:50%;
  padding:8px 12px;
  margin-right:12px;
  border:1px solid rgba(0,0,0,.15);
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  text-align:center;
  color:#0d0b1d
}

.full-dashboard .full-leaderboard .name-card{
  display:flex;
  background:#fff;
  border:1px solid rgba(0,0,0,.15);
  box-sizing:border-box;
  border-radius:25px;
  margin:4px 0;
  padding:22px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  align-items:center;
  color:#0c092a
}

.full-dashboard .full-leaderboard .name-card.me{
  background:var(--activity_background)
}

.full-dashboard .full-leaderboard .name-card.me *{
  color:var(--greeting_text_color)
}

.full-dashboard .full-leaderboard .name-card.me .main::before{
  color:var(--greeting_text_color);
  border-color:var(--greeting_text_color)
}

.full-dashboard .full-leaderboard .name-card.me .compid{
  color:#0c092a
}

.full-dashboard .full-leaderboard .main{
  display:flex;
  align-items:center
}

.full-dashboard .full-leaderboard .main .avatar{
  border-radius:50%;
  width:45px;
  margin-right:12px
}

.full-dashboard .full-leaderboard .main .points{
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:13px;
  line-height:140%;
  color:#858494;
  margin:0 0 0 7px
}

.full-dashboard .full-leaderboard .main .name{
  margin:0;
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:#0d0b1d;
  text-transform:capitalize
}

.full-dashboard .full-leaderboard .main .compid{
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:14px;
  color:#747085
}

.full-dashboard .full-leaderboard .main .compid .logo{
  width:14px;
  margin-right:6px
}

.full-dashboard .full-leaderboard::before{
  content:"";
  display:block;
  width:100%;
  height:1000vh;
  background-color:#efeefc;
  position:absolute;
  left:0;
  top:115px;
  z-index:-1
}

.hide-last{
  display:none
}

.row.badges{
  margin:0;
  display:flex;
  justify-content:space-around;
  padding:20px 22px 70px;
  border-radius:0px;
  margin-bottom:-60px;
  padding-top:15px !important
}

.row.badges svg{
  width:100px
}

.row.badges svg rect{
  height:105px;
  width:115px
}

.profile-page .row.badges{
  background:none;
  margin-bottom:140px;
  flex-wrap:wrap
}

.badge{
  transition:.7s all;
  opacity:.5;
  transform-origin:center;
  transform:scale(0.9);
  cursor:pointer;
  width:33.3333333333vw
}

.badge img{
  max-width:60px;
  padding:10px;
  background:#fff;
  border:2px solid var(--activity_background);
  border-radius:50%;
  margin-bottom:5px
}

.badge.active{
  opacity:1;
  transform:scale(1)
}

p.badge-name{
  font-family:"Rubik";
  padding:0;
  margin:0;
  color:var(--module_name);
  font-size:14px
}

.swiper-button-prev,.swiper-button-next{
  background:url(/static/media/prev.9e22180b.svg);
  width:20px;
  height:20px;
  background-size:contain;
  position:absolute;
  top:50%;
  margin-top:-2px;
  z-index:10;
  cursor:pointer
}

.swiper-button-prev{
  left:10px;
  transform:rotate(180deg)
}

.swiper-button-next{
  right:10px
}

.swiper-button-disabled{
  opacity:.5
}

.swiper-container{
  max-width:calc(100vw - 80px);
  position:initial !important;
  padding:20px 0 10px 0 !important;
  overflow:visible !important
}

.slider-badges{
  position:relative
}

.header-greetings{
  padding:0;
  left:0px;
  top:0px;
  z-index:100;
  background:transparent;
  height:150px;
  width:100%;
  margin-bottom:20px;
  transition:.5s all
}

.header-greetings .logo-wrap{
  margin:22px;
  padding:20px;
  background:rgba(0,0,0,.3);
  position:fixed;
  border-radius:25px;
  animation:totransparent .25s forwards;
  width:calc(100% - 84px);
  z-index:1000;
  max-height:22px !important;
  transition:.5s all
}

@media(min-width: 544px){
  .header-greetings .logo-wrap{
    max-width:480px;
    width:100%;
    margin:0px;
    padding:0px
  }
}

.header-greetings .logo-wrap img,.header-greetings .logo-wrap svg{
  max-height:30px;
  max-width:120px;
  min-height:20px
}

@media(min-width: 544px){
  .header-greetings .logo-wrap img,.header-greetings .logo-wrap svg{
    margin:20px
  }
}

.header-greetings.bg-solid .logo-wrap{
  animation:tosolid .25s forwards;
  width:calc(100% - 30px);
  border-radius:0 0 25px 25px;
  margin:0;
  padding:15px
}

@media(min-width: 544px){
  .header-greetings.bg-solid .logo-wrap{
    margin:0;
    padding:0;
    max-height:60px !important
  }
}

.header-greetings .left{
  position:relative;
  height:21px;
  left:21px;
  top:12px;
  border-radius:32px
}

.header-greetings .greetings{
  height:60px;
  display:flex
}

.header-greetings .greetings #hi{
  left:24px
}

.header-greetings .greetings #hi #greet{
  height:80px;
  display:flex;
  align-items:center;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:19px;
  line-height:24px;
  color:#fff
}

.header-greetings .greetings #hi #greet svg{
  padding-right:8px
}

.header-greetings .greetings #hi #greet svg circle:nth-child(1){
  stroke:var(--activity_background)
}

.header-greetings .greetings #hi #greet svg circle:nth-child(2){
  stroke:var(--greeting_text_color)
}

.header-greetings .greetings #hi #greet .name{
  color:var(--greeting_text_color);
  text-transform:capitalize;
  margin-left:5px
}

.header-greetings .greetings .avatar{
  border-radius:50%;
  margin:0 12px 0 20px;
  width:44px
}

.header-greetings .greetings img{
  border-radius:50%;
  top:6%;
  left:6%;
  height:88%;
  width:88%
}

@keyframes tosolid{
  0%{
    background:rgba(0,0,0,.3);
    height:150px
  }

  100%{
    background:#0d0b1d;
    height:110px
  }
}

@keyframes tosmaller{
}

@keyframes totransparent{
  0%{
    background:#0d0b1d;
    height:110px
  }

  100%{
    background:rgba(0,0,0,.3);
    height:150px
  }
}

@keyframes tobigger{
}

.card-two{
  background:rgba(0,0,0,.15);
  border-radius:20px;
  margin:0 24px 24px;
  display:flex;
  flex-direction:column;
  justify-content:space-between
}

.card-two .top-featured{
  padding:24px 0;
  display:block
}

.card-two .top-featured p{
  left:124px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  margin:16px 60px;
  display:flex;
  align-items:center;
  letter-spacing:.08em;
  color:#fff;
  opacity:.7;
  text-transform:uppercase
}

.card-two .top-featured img{
  max-height:170px;
  border-radius:0;
  border-radius:10px;
  margin-top:-120px
}

.card-two a{
  padding:0;
  margin:0;
  border:none;
  color:#41295a;
  font-weight:500
}

.card-two .mid-featured{
  margin:0 50px 16px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:18px;
  line-height:135%;
  text-align:center;
  color:#fff
}

.card-two .btm-featured{
  display:flex;
  margin:32px;
  margin-top:10px;
  justify-content:center
}

.card-two .btm-featured button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:12px 20px;
  height:44px;
  background:#fff;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  color:#41295a;
  border:none;
  padding:12px 16px;
  transition:.2s all;
  height:54px;
  background:#fff;
  border-radius:30px;
  font-family:Rubik;
  font-style:normal;
  font-weight:600;
  font-size:14px;
  line-height:140%;
  color:#000;
  border:none;
  padding:12px 26px;
  min-width:150px
}

.card-two .btm-featured button a{
  font-weight:600 !important
}

.card-two .btm-featured button svg{
  margin-right:10px
}

.card-two .btm-featured button:hover{
  transform:scale(0.9)
}

.card-two .btm-featured .action-button.loading{
  background-color:#000;
  color:#fff;
  pointer-events:none
}

.card-two .btm-featured .action-button.loading svg{
  display:none
}

.card-two .btm-featured img{
  right:15px;
  bottom:42px
}

.card-two img{
  border-radius:50%
}

.loading-spinner{
  width:20px;
  height:20px;
  border:2px solid #fff;
  border-radius:50%;
  border-top-color:transparent;
  animation:spin 1s linear infinite;
  margin:0 auto
}

@keyframes spin{
  to{
    transform:rotate(360deg)
  }
}

#signin-header{
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:#fff;
  margin-bottom:20px
}

.lagoon-logo{
  max-height:34px;
  margin:40px 0 10px 0;
  max-width:222px
}

.signUp{
  background:var(--background)
}

#signin-form{
  display:flex;
  flex-direction:column;
  align-items:center;
  height:100vh
}

#signin-form input{
  max-width:222px;
  height:30px;
  font-size:16px;
  text-align:center;
  margin:7px;
  background:no-repeat;
  border:0;
  font-family:Rubik;
  font-style:normal;
  font-weight:400;
  line-height:19px;
  display:flex;
  align-items:center;
  border-bottom:2px solid rgba(255,255,255,.15);
  padding:5px 16px;
  color:#fff
}

#signin-form input::placeholder{
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:16px;
  line-height:19px;
  display:flex;
  align-items:center;
  color:rgba(255,255,255,.6)
}

#signin-form input:focus{
  outline:none;
  border-bottom:2px solid var(--buttons_background);
  cursor:pointer
}

#signin-form label{
  margin-top:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:11px;
  line-height:150%;
  color:#fff
}

#signin-form label[for=company]{
  margin-right:7px
}

#signin-form button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#efeefc;
  color:#41295a;
  padding:0;
  border-radius:99px;
  line-height:19px;
  margin:10px auto 0;
  width:168px;
  height:57px;
  border:0;
  font-weight:500;
  cursor:pointer;
  background:var(--buttons_background);
  font-size:18px;
  color:var(--buttons_color)
}

#signin-form button:hover{
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

#signin-form button:active{
  transform:scale(0.95, 0.95)
}

#signin-form button.no-button{
  background:transparent;
  margin-top:20px;
  width:300px
}

#signin-form p:not(#signin-header){
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:11px;
  line-height:150%;
  text-align:center;
  color:#ffe710;
  margin-top:2px
}

#signin-form .react-tel-input{
  max-width:251px
}

#signin-form .react-tel-input .form-control{
  border-radius:0;
  width:100%;
  margin:0;
  max-width:none
}

#signin-form .react-tel-input .flag-dropdown{
  background-color:transparent;
  border:0
}

.registration_consent{
  display:flex;
  color:#fff;
  max-width:300px;
  text-align:left
}

.registration_consent input{
  float:left;
  margin-top:-3px !important;
  padding-top:0 !important;
  width:20px
}

.registration_consent input[type=checkbox]{
  width:30px;
  min-width:18px;
  accent-color:var(--buttons_background)
}

.registration_agree{
  color:#fff;
  max-width:300px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:11px;
  line-height:150%;
  margin-top:25px
}

.quiz header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed
}

@media screen and (min-width: 544px){
  .quiz header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.quiz header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.quiz header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.quiz .questions{
  background:#fff;
  width:80%;
  margin:0 auto;
  border-radius:20px;
  padding:20px 10px;
  margin-bottom:100px
}

.quiz .questions li{
  list-style-type:none;
  text-align:left
}

.quiz .questions li label{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  height:auto;
  width:auto;
  margin-left:5px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  padding:10px;
  cursor:pointer;
  z-index:90;
  margin:auto;
  width:200px;
  margin-bottom:10px;
  background:linear-gradient(268.23deg, var(--response_gradient_color_one) 0%, var(--response_gradient_color_two) 100%);
  color:var(--response_text_color);
  border-radius:99px;
  text-align:center
}

.quiz .questions li input[type=radio]{
  opacity:.01;
  z-index:100;
  display:none
}

.quiz .questions .question{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  letter-spacing:.08em;
  color:#858494;
  margin:5px 28px 0
}

.quiz .questions .q-description{
  min-height:67px;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:10px 8px 20px 8px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:140%;
  color:#0c092a;
  opacity:.9
}

.quiz .card-two{
  padding-bottom:25px;
  margin:80px 40px 20px 40px
}

.quiz .card-two .btm-featured{
  display:none
}

.quiz .popup{
  background:#fff;
  border-radius:25px;
  max-width:420px;
  position:absolute;
  width:80%;
  transform:translate(-50%);
  left:50%;
  padding:20px;
  top:30%;
  height:250px;
  box-shadow:rgba(0,0,0,.35) 0px 5px 15px
}

.quiz .popup h2{
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:var(--background)
}

.quiz .popup .popup-inner{
  transform:translate(-50%, -50%);
  left:50%;
  top:50%;
  width:95%;
  position:absolute
}

.quiz .popup .popup-inner p{
  font-size:16px;
  line-height:19px;
  color:#0d0b1d
}

@media screen and (min-width: 409px){
  .quiz .popup{
    height:326px
  }
}

@media screen and (min-width: 499px){
  .quiz .popup{
    top:260px;
    height:290px
  }
}

.quiz .popup button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#41295a;
  color:#fff;
  width:50%;
  padding:10px;
  margin:0 auto;
  height:35px;
  background:linear-gradient(268.23deg, var(--response_gradient_color_two) 0%, var(--response_gradient_color_one) 100%);
  border-radius:99px
}

.quiz footer li{
  opacity:.3
}

.qr{
  height:90vh;
  overflow:hidden
}

.qr header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000
}

@media screen and (min-width: 544px){
  .qr header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.qr header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.qr header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.qr #video{
  height:80%
}

.qr h1.qr-instruction{
  color:#fff;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  margin-top:60px
}

.qr .qr-extra{
  font-weight:300;
  color:#fff;
  font-family:Rubik;
  font-size:1.2em
}

.activities{
  position:relative
}

.activities .points-box{
  display:block;
  background:#0d0b1d;
  border-radius:25px 25px 0 0;
  padding:6px 20px;
  font-family:"Rubik";
  color:#fff;
  font-size:.9em
}

.activities .points-box .bold{
  font-weight:bold;
  color:#f90
}

.activities .card-three{
  background:#fffcf4;
  border-radius:25px 25px 0px 0px;
  margin:-30px 0px 60px 0px;
  padding:18px 24px 24px;
  padding-bottom:45px !important
}

.activities .card-three .business-card-btn{
  display:none
}

.activities .card-three .business-card-btn.view-all{
  position:absolute;
  display:block;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  background:blue;
  background:var(--buttons_background) linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  padding:4px 20px !important;
  top:22px;
  color:var(--buttons_color);
  right:22px
}

@media screen and (min-width: 600px){
  .activities .card-three{
    padding-bottom:60px
  }
}

.activities .card-three .dash{
  border:1px solid rgba(0,0,0,.15);
  width:26px;
  margin:0 auto
}

.activities .card-three div:nth-of-type(3) .quiz-card{
  border-top-left-radius:25px;
  border-top-right-radius:25px
}

.activities .card-three div:nth-of-type(3) .quiz-card.disabled::after{
  border-top-left-radius:25px;
  border-top-right-radius:25px
}

.activities .card-three div:nth-last-of-type(2) .quiz-card{
  border-bottom-left-radius:25px;
  border-bottom-right-radius:25px
}

.activities .card-three div:nth-last-of-type(2) .quiz-card.disabled::after{
  border-bottom-left-radius:25px;
  border-bottom-right-radius:25px
}

.activities .card-three .top-three{
  margin:8px auto 12px;
  display:flex;
  justify-content:space-between;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:140%;
  color:#0c092a
}

.activities .card-three .top-three a{
  font-size:14px;
  color:#41295a;
  text-decoration:none
}

.activities .card-three .quiz-card{
  position:relative;
  background:var(--activity_background);
  background-repeat:no-repeat;
  background-size:cover;
  box-shadow:var(--activity_background);
  margin-bottom:4px;
  background-blend-mode:difference;
  overflow:hidden
}

.activities .card-three .quiz-card a,.activities .card-three .quiz-card .link-to{
  position:relative;
  display:flex;
  align-items:center;
  justify-content:space-around;
  width:100%;
  text-decoration:none
}

.activities .card-three .quiz-card .left{
  margin:12px;
  width:63px;
  min-width:63px;
  height:64px;
  display:flex;
  align-items:center;
  justify-content:center;
  background:#fffcf4;
  border-radius:15px
}

.activities .card-three .quiz-card .left img{
  width:40px
}

.activities .card-three .quiz-card .left .left-disabled{
  display:none
}

.activities .card-three .quiz-card .left.completed img.preview-image{
  width:90% !important;
  overflow:hidden !important
}

.activities .card-three .quiz-card .middle{
  min-width:120px
}

.activities .card-three .quiz-card .middle .mid-flex{
  display:flex;
  align-items:center
}

.activities .card-three .quiz-card .middle .quiz-name{
  margin:0;
  margin-top:-14px;
  margin-left:10px;
  text-decoration:none;
  text-align:left;
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:var(--activity_text_color)
}

.activities .card-three .quiz-card .middle .points{
  visibility:visible;
  position:absolute;
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:12px;
  line-height:14px;
  opacity:.7;
  color:var(--activity_text_color)
}

.activities .card-three .quiz-card .middle img{
  visibility:hidden
}

.activities .card-three .quiz-card .middle .quiz-description{
  font-weight:normal;
  font-size:12px;
  color:#858494;
  margin:6px 0px
}

.activities .card-three .quiz-card .middle.completed .quiz-name{
  margin-top:-20px
}

.activities .card-three .quiz-card .middle.completed .points{
  visibility:visible;
  left:0;
  z-index:100;
  margin-left:97px;
  margin-top:5px;
  animation-duration:1.5s;
  animation-name:fadeIn
}

@keyframes fadeIn{
  0%{
    opacity:0
  }

  100%{
    opacity:1
  }
}

.activities .card-three .quiz-card .middle.completed img{
  margin-right:6px;
  visibility:visible
}

.activities .card-three .quiz-card .right{
  margin-left:auto;
  background:var(--buttons_background);
  border-radius:15px;
  width:63px;
  height:63px;
  display:flex;
  justify-content:center;
  align-items:center;
  margin-right:12px
}

.activities .card-three .quiz-card .right svg path{
  stroke:var(--buttons_color)
}

.activities .card-three .quiz-card .right .check-mark,.activities .card-three .quiz-card .right .right-disabled{
  display:none
}

.activities .card-three .quiz-card .right.completed{
  background:rgba(255,255,255,.1);
  border-radius:15px
}

.activities .card-three .quiz-card .right.completed .check-mark{
  display:block;
  animation-duration:1.5s;
  animation-name:fadeIn
}

.activities .card-three .quiz-card .right.completed .check-mark svg path{
  stroke:#32ff47
}

.activities .card-three .quiz-card.completed::after{
  content:"";
  pointer-events:none;
  background:rgba(0,0,0,.35) !important;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:0
}

.activities .card-three .quiz-card.completed.incorrect .middle.completed .points{
  display:none
}

.activities .card-three .quiz-card.disabled .left{
  z-index:1
}

.activities .card-three .quiz-card.disabled img{
  display:none
}

.activities .card-three .quiz-card.disabled .left-disabled{
  display:block;
  background:linear-gradient(114.2deg, rgba(117, 73, 174, 0.1) 15.5%, rgba(93, 37, 166, 0.1) 93.71%);
  box-shadow:inset 0px -2.75px 2.5px rgba(138,56,244,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px
}

.activities .card-three .quiz-card.disabled .right-disabled{
  display:block;
  border-radius:15px;
  z-index:1
}

.activities .card-three .quiz-card.disabled .activity-link{
  pointer-events:none
}

.activities .card-three .wallet{
  display:none
}

.activities .db-buttons{
  display:flex;
  justify-content:space-around
}

.activities .db-buttons button{
  cursor:pointer;
  margin-top:10px;
  width:150px;
  height:34px;
  background:#4605f5;
  border-radius:100px;
  font-family:"Roboto";
  font-style:normal;
  font-weight:bold;
  font-size:15px;
  line-height:22px;
  text-align:center;
  color:#fff;
  transition-duration:.4s
}

.activities .db-buttons button:hover{
  background-color:#4e4ef7;
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.activities .db-buttons button:active{
  transform:scale(0.95, 0.95)
}

.activities .card-three .wallet .wallet-flex #new-avatar img{
  max-width:45px;
  margin-top:7px;
  margin-left:0px
}

.activities .wallet-card img,.activities .wallet-card .emoji{
  width:35px !important;
  position:relative;
  font-size:20px;
  line-height:50px;
  padding:0;
  margin-left:7px
}

.activities .card-three .wallet{
  display:flex;
  background:#fff;
  margin:10px auto;
  box-shadow:0px 0px 7px #ccc;
  padding:40px 10px 10px 10px;
  border-radius:20px;
  justify-content:center;
  pointer-events:none;
  margin-top:30px !important;
  position:relative;
  overflow:hidden
}

.activities .wallet-card{
  border-radius:10px;
  display:block;
  background:rgba(255,255,255,.25);
  padding:0px;
  overflow:hidden;
  width:50px;
  pointer-events:none;
  height:50px
}

.activities .wallet-card.bio{
  display:none
}

.activities .wallet-card.earned-reward{
  background-size:contain
}

.activities .wallet-card.avatar{
  background-size:cover
}

.activities .wallet{
  margin:0 -24px !important;
  border-radius:0 !important;
  background:var(--rewards_and_wallet_background) !important;
  background-blend-mode:color-dodge
}

.activities .wallet:after{
  content:"Collectibles";
  display:block;
  background:#fff;
  padding:6px 20px;
  font-family:"Rubik";
  color:#000;
  font-size:.9em;
  box-shadow:0px -3px 20px rgba(0,0,0,.05);
  height:100%;
  position:absolute;
  left:0;
  top:0px;
  right:0;
  height:16px
}

.activities .wallet-button{
  display:block;
  margin-top:-7px;
  width:35px;
  right:0;
  position:absolute;
  height:70px;
  z-index:100
}

.activities .wallet-svg{
  width:8px;
  margin-top:22px;
  height:auto
}

.activities .wallet-link{
  color:#000 !important;
  font-weight:bold;
  font-family:"Rubik";
  font-size:10px
}

@keyframes coin-animation{
  0%{
    transform:scale(0)
  }

  100%{
    transform:scale(1)
  }
}

.dashboard .disabled-project{
  font-size:25px;
  margin-top:100px;
  color:#fff
}

.dashboard .profile-titles,.dashboard .wallet{
  display:none
}

.dashboard .badges{
  padding-top:35px
}

.dashboard .header-greetings .greetings{
  padding-top:110px
}

.dashboard .packets{
  top:0;
  position:inherit
}

.dashboard .packets header{
  display:none
}

.dashboard .packets .activities .wallet{
  display:block
}

.dashboard .packets .card-three.disabled{
  display:none
}

.dashboard .packets .no-activity{
  display:block;
  position:absolute;
  background:#fffcf4;
  border-radius:25px 25px 0px 0px;
  padding-top:40px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:#0d0b1d;
  height:300px;
  z-index:-1;
  width:100%
}

.dashboard .packets .no-activity .qr-no-activity{
  color:#b63fff
}

.dashboard .profile{
  margin-bottom:0
}

.dashboard .profile button{
  display:none
}

.dashboard .profile .applewalletcard{
  display:none
}

.dashboard .card-three{
  padding-bottom:5px !important
}

.dashboard .card-three a{
  text-decoration:none
}

.dashboard .card-three a button.business-card-btn.my-collectables{
  visibility:visible;
  display:block;
  margin:5px auto;
  padding:0;
  height:30px;
  opacity:1
}

.dashboard .card-three .wallet{
  display:flex;
  margin:10px auto;
  justify-content:center;
  overflow:visible !important;
  border-radius:20px !important;
  pointer-events:all
}

.dashboard .card-three .wallet p{
  display:none
}

.dashboard .card-three .wallet .visible.popup{
  top:25%;
  position:fixed;
  padding-top:40px;
  background:var(--activity_background)
}

.dashboard .card-three .wallet .popup .emoji{
  font-size:130px;
  margin-bottom:20px;
  opacity:.8;
  width:auto !important;
  margin-left:0
}

.dashboard .card-three .wallet .popup .popup-inner{
  position:relative
}

.dashboard .card-three .wallet .popup .wallet-image{
  margin-left:0;
  width:150px !important;
  height:auto;
  border-radius:20px
}

.dashboard .card-three .wallet .popup .video-wrapper{
  display:none
}

.dashboard .card-three .wallet .popup .popup-text,.dashboard .card-three .wallet .popup div,.dashboard .card-three .wallet .popup p{
  color:#fff
}

.dashboard .card-three .wallet .popup button{
  margin-bottom:0 !important
}

.dashboard .card-three .wallet .popup .popup-inner .wallet-card{
  margin:0 auto 20px;
  width:100%;
  padding:0;
  background:transparent;
  height:-webkit-fit-content;
  height:-moz-fit-content;
  height:fit-content
}

.dashboard .card-three .wallet .popup .popup-inner .wallet-card .emoji{
  line-height:150px
}

.dashboard .card-three .wallet .wallet-flex{
  display:flex;
  width:100%;
  justify-content:space-evenly
}

.dashboard .card-three .wallet .wallet-flex .emoji{
  font-size:35px
}

.dashboard .card-three .wallet .wallet-flex #new-avatar img{
  max-width:45px
}

.dashboard .card-three .wallet .wallet-flex>:nth-child(n+5){
  display:none
}

.dashboard footer li:not(:first-child){
  opacity:.3
}

.dashboard .projects{
  font-family:"Rubik";
  color:#fff
}

.dashboard .projects h2{
  margin-bottom:30px
}

.dashboard .projects div{
  margin-bottom:10px
}

.dashboard .projects div a{
  color:#fff;
  text-decoration:none
}

.packets{
  margin-top:30px;
  position:relative;
  top:50px
}

.packets header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed
}

@media screen and (min-width: 544px){
  .packets header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.packets header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.packets header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.packets button{
  visibility:hidden;
  cursor:pointer;
  margin-top:10px;
  width:150px;
  height:34px;
  background:#4605f5;
  border-radius:100px;
  font-family:"Roboto";
  font-style:normal;
  font-weight:bold;
  font-size:15px;
  line-height:22px;
  text-align:center;
  color:#fff;
  transition-duration:.4s;
  margin-bottom:160px
}

.packets button:hover{
  background-color:#4e4ef7;
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.packets button:active{
  transform:scale(0.95, 0.95)
}

.packets .activities .wallet{
  display:none
}

.packets .no-activity{
  display:none;
  background:#fffcf4;
  border-radius:25px 25px 0px 0px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:#0d0b1d;
  height:200px;
  width:100%
}

.activity .activity-link{
  text-decoration:none
}

.activity .quiz-card.bio-hidden{
  display:none
}

.activity .quiz-card.disabled{
  opacity:1;
  position:relative
}

.activity .quiz-card.disabled::after{
  left:0;
  top:-2px;
  position:absolute;
  content:"Scan QR to unlock";
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  color:#fff;
  line-height:80px;
  width:calc(100% - 4px);
  height:98%;
  border:2px solid #41295a;
  background:var(--activity_background)
}

.activity .quiz-card.disabled .card-three .right-disabled,.activity .quiz-card.disabled .card-three .left-disabled{
  display:block;
  z-index:1
}

.activity .left img{
  width:64px
}

.admin .logout{
  display:flex;
  justify-content:end
}

.admin .logout button{
  border:none;
  background:none;
  margin:16px
}

.admin .logout p{
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:16px;
  line-height:19px;
  color:rgba(255,255,255,.6)
}

.bio header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:relative;
  z-index:10000
}

@media screen and (min-width: 544px){
  .bio header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.bio header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.bio header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.bio header .top p{
  margin:0 auto
}

.bio .email-form{
  display:flex;
  flex-direction:column;
  align-items:center;
  padding-bottom:150px
}

.bio .email-form input{
  max-width:222px;
  height:30px;
  font-size:16px;
  text-align:center;
  margin:7px;
  background:no-repeat;
  border:0;
  font-family:Rubik;
  font-style:normal;
  font-weight:400;
  line-height:19px;
  display:flex;
  align-items:center;
  border-bottom:2px solid rgba(255,255,255,.1);
  padding:10px;
  color:#fff
}

.bio .email-form input::placeholder{
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:16px;
  line-height:19px;
  display:flex;
  align-items:center;
  color:rgba(255,255,255,.6)
}

.bio .email-form input:focus{
  outline:none;
  border-bottom:2px solid var(--buttons_background);
  cursor:pointer
}

.bio .email-form label{
  margin-right:15px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:15px;
  line-height:150%;
  color:#fff
}

.bio .email-form label[for=company]{
  margin-right:7px
}

.bio .email-form button{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:16px 133px;
  width:327px;
  height:56px;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:16px;
  line-height:150%;
  background-color:#efeefc;
  color:#41295a;
  padding:0;
  border-radius:99px;
  line-height:19px;
  margin:23px auto 0;
  width:168px;
  height:57px;
  border:0;
  font-weight:500;
  cursor:pointer;
  background:var(--buttons_background);
  color:#fff
}

.bio .email-form button:hover{
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.bio .email-form button:active{
  transform:scale(0.95, 0.95)
}

.bio p:not(.email-form){
  font-family:Rubik;
  font-style:normal;
  font-weight:normal;
  font-size:12px;
  line-height:150%;
  text-align:center;
  color:#ffe710;
  margin-top:2px
}

.bio .btm-featured{
  display:none
}

.bio .top-featured{
  padding:10px 0
}

button.business-card-btn{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:12px 20px;
  height:44px;
  background:#fff;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  color:#41295a;
  border:none;
  margin-right:10px;
  height:30px;
  font-size:12px;
  pointer-events:auto;
  opacity:.6
}

.back-side .popup{
  height:100%;
  width:100%;
  max-width:420px;
  font-family:"Rubik";
  color:#fff;
  top:0 !important
}

.back-side .popup img{
  border-radius:50%;
  margin-top:100px;
  max-width:270px
}

.back-side .popup .names{
  font-size:2em;
  margin-top:40px
}

.back-side .popup .module-closer{
  display:none
}

.back-side .popup .card-name{
  display:flex;
  align-items:center;
  margin-top:20px;
  font-weight:bold
}

.back-side .popup .card-email{
  margin-top:10px;
  font-size:18px
}

.back-side .popup .card-email p{
  font-style:italic;
  margin:5px
}

.back-side .popup .card-company{
  margin-top:10px;
  font-size:18px
}

.back-side .popup .card-company p{
  font-style:italic;
  margin:5px
}

.back-side .popup .show-details{
  height:30px;
  margin-top:50px
}

.back-side .popup .highlight{
  color:#858494 !important;
  font-weight:100 !important
}

.header-greetings{
  pointer-events:none
}

.progress-bar{
  height:25px;
  width:93%;
  border-radius:50px;
  margin:15px auto;
  display:flex;
  background-color:#e0e0de
}

.progress-bar .filler{
  height:100%;
  width:50%;
  background-color:#b596e7;
  transition:width 1s ease-in-out;
  border-radius:inherit;
  text-align:center;
  display:flex;
  justify-content:space-between
}

.progressVisualRewards{
  left:5%;
  width:90%;
  margin-top:20px;
  position:relative;
  height:15px;
  margin-bottom:-20px;
  z-index:1
}

.progressVisualRewards div{
  color:#fff;
  font-family:"Rubik";
  font-size:10px;
  display:block;
  line-height:15px;
  font-weight:bold;
  position:absolute;
  top:5px
}

.progressVisualRewards div::before{
  content:"";
  width:18px;
  display:block;
  height:18px;
  top:-2px;
  position:absolute;
  background:url(/static/media/coin.6642ec63.png);
  z-index:1;
  background-size:contain;
  filter:grayscale(1);
  left:-8px
}

.progressVisualRewards div::after{
  content:"";
  width:2px;
  z-index:0;
  background:#fff;
  display:block;
  height:3px;
  position:absolute;
  left:0;
  top:-8px;
  opacity:.5
}

.progressVisualRewards div span{
  position:absolute;
  font-size:.85em;
  line-height:1em;
  font-weight:normal;
  width:90px;
  transform:translateX(-50%);
  top:-18px;
  color:#fff;
  opacity:.5
}

.progressVisualRewards div.active span{
  opacity:1;
  color:#f90
}

.progressVisualRewards div.active::before{
  filter:none
}

.progressVisualRewards div.active::after{
  opacity:1
}

.progressVisualFull{
  height:25px;
  width:90%;
  border-radius:50px;
  margin:15px auto;
  margin-top:5px;
  display:flex;
  position:relative
}

.progressVisualFull:before{
  display:block;
  background:linear-gradient(45deg, rgba(255, 255, 255, 0), #fff 10%, #fff 90%, rgba(255, 255, 255, 0) 100%);
  width:96%;
  height:1px;
  left:2%;
  top:24px;
  border-radius:25px;
  position:absolute
}

.progressVisualFull:after{
  display:block;
  background:linear-gradient(45deg, rgba(255, 255, 255, 0), #fff 10%, #fff 90%, rgba(255, 255, 255, 0) 100%);
  width:96%;
  height:1px;
  left:2%;
  top:0;
  border-radius:25px;
  position:absolute
}

.progressVisualFull .progressVisualPart{
  transition:width 2s;
  position:relative;
  background:var(--buttons_background) linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%) !important
}

.progressVisualFull .progressVisualPart:first-of-type{
  border-top-left-radius:50px;
  border-bottom-left-radius:50px;
  height:inherit;
  border-left:0 !important
}

.progressVisualFull .progressVisualPart:last-of-type{
  border-top-right-radius:50px;
  border-bottom-right-radius:50px
}

.progressVisualFull .progressVisualPart:nth-child(2):before{
  content:"";
  position:absolute;
  background:#000;
  opacity:.1;
  top:0;
  right:0;
  bottom:0;
  left:0
}

.progressVisualFull .progressVisualPart:nth-child(3):before{
  content:"";
  position:absolute;
  background:#000;
  opacity:.2;
  top:0;
  right:0;
  bottom:0;
  left:0
}

.progressVisualFull .progressVisualPart:nth-child(4):before{
  content:"";
  position:absolute;
  background:#000;
  opacity:.3;
  top:0;
  right:0;
  bottom:0;
  left:0
}

.progressVisualFull .progressVisualPart:nth-child(5):before{
  content:"";
  position:absolute;
  background:#000;
  opacity:.4;
  top:0;
  right:0;
  bottom:0;
  left:0
}

.progressVisualFull .progressVisualPart::after{
  position:absolute;
  margin-top:30px;
  font-size:10px;
  color:#fff;
  font-family:"Rubik";
  font-weight:regular;
  transform:translate(-50%)
}

.progressVisualFull .progressVisualPart.show-text:nth-of-type(2){
  border-left:1px solid #fff
}

.progressVisualFull .points-total{
  position:absolute;
  visibility:hidden;
  font-size:10px;
  color:#fff;
  font-family:"Rubik";
  font-weight:regular;
  transform:translateX(-20px);
  line-height:23px;
  padding:0 6px;
  background:#470c65;
  border:1px solid #fff;
  height:23px;
  margin-top:0;
  z-index:1;
  border-radius:50%
}

.progressVisualFull .points-total.show-text{
  visibility:visible
}

.rewards{
  background:var(--rewards_and_wallet_background);
  background-blend-mode:color;
  background-size:cover;
  background-attachment:fixed;
  background-position-x:left;
  height:calc(100vh - 120px);
  padding:1px 0 10px;
  margin-bottom:180px;
  height:100vh;
  overflow:hidden;
  overflow-y:scroll;
  position:fixed;
  width:100%;
  max-width:480px
}

.rewards .profile-wrapper{
  padding:0 0;
  border-radius:25px 25px 0px 0px;
  margin-top:68px
}

.rewards .profile-wrapper .rewards-header{
  align-items:center;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  padding:24px 0;
  font-size:19px;
  line-height:24px;
  background:var(--rewards_header_background);
  background-size:cover;
  background-attachment:fixed;
  background-position-x:left
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex{
  display:flex;
  justify-content:space-between
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex .avatar{
  border-radius:50%;
  margin:0 12px 0 20px;
  width:44px
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex img{
  border-radius:50%;
  top:6%;
  left:6%;
  height:88%;
  width:88%
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex .points-box{
  width:200px;
  position:relative;
  text-align:left;
  background:rgba(0,0,0,.6);
  box-shadow:2px 2px 8px 15px rgba(0,0,255,.04);
  border-radius:10px;
  padding:10px 30px;
  margin-right:24px
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex .points-box .points-centered{
  position:absolute;
  top:50%;
  transform:translateY(-50%)
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex .points-box p.points-text{
  color:#fff;
  font-size:32px;
  margin:0 0 0 0
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex .points-box p.points-caption{
  color:#ccc;
  font-size:14px;
  margin:0;
  font-weight:300;
  margin-top:5px
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex svg circle:nth-child(1){
  stroke:var(--activity_background)
}

.rewards .profile-wrapper .rewards-header .rewards-header-flex svg circle:nth-child(2){
  stroke:var(--greeting_text_color)
}

.rewards .profile-wrapper .rewards-body{
  padding:40px 24px;
  padding-bottom:250px
}

.rewards .profile-wrapper .dash{
  border:1px solid rgba(0,0,0,.15);
  width:26px;
  margin:0 auto
}

.rewards .profile-wrapper p.profile-titles{
  font-size:32px;
  margin:0;
  align-items:center;
  text-align:left;
  padding-left:0;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  line-height:24px;
  color:#fff
}

.rewards .profile-wrapper p.profile-titles.mb-11{
  margin-bottom:11px
}

.rewards .reward{
  margin-top:20px;
  position:relative;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between
}

.rewards header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed
}

@media screen and (min-width: 544px){
  .rewards header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.rewards header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.rewards header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.rewards button:not(.redeem-btn){
  visibility:hidden;
  cursor:pointer;
  margin-top:10px;
  width:150px;
  height:34px;
  background:#4605f5;
  border-radius:100px;
  font-family:"Roboto";
  font-style:normal;
  font-weight:bold;
  font-size:15px;
  line-height:22px;
  text-align:center;
  color:#fff;
  transition-duration:.4s;
  margin-bottom:160px
}

.rewards button:not(.redeem-btn):hover{
  background-color:#4e4ef7;
  transform:translateY(1px);
  box-shadow:0 10px 20px rgba(0,0,0,.2)
}

.rewards button:not(.redeem-btn):active{
  transform:scale(0.95, 0.95)
}

.rewards .btn-redeem{
  display:block
}

.rewards .activities .wallet{
  display:none
}

.rewards .wallet-card.activity-nft{
  width:100%;
  font-family:"Rubik";
  font-style:normal;
  font-weight:400;
  font-size:18px;
  position:relative;
  line-height:19px;
  height:auto;
  color:#fff;
  margin-bottom:20px;
  background:var(--activity_background) !important;
  box-shadow:inset 0px -2.75px 2.5px rgba(138,56,244,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px;
  padding:20px 30px;
  display:flex
}

.rewards .wallet-card.activity-nft .emoji{
  margin-top:0
}

.rewards .wallet-card.activity-nft::after{
  opacity:0
}

.rewards .wallet-card.activity-nft p{
  margin-bottom:0
}

.rewards .wallet-card.activity-nft img{
  width:auto;
  height:90px
}

.rewards .wallet-card.activity-nft .reward-card-right{
  text-align:left;
  padding-left:20px;
  align-self:center
}

.rewards .wallet-card.activity-nft .reward-card-right p:first-of-type{
  margin-top:0;
  font-size:1.2em
}

.rewards .wallet-card.activity-nft .reward-card-right .price-in-points{
  font-size:14px;
  font-weight:500 !important;
  color:rgba(255,255,255,.8) !important;
  margin:0;
  font-weight:300;
  margin-top:5px
}

.rewards .wallet-card.activity-nft .reward-card-right .instruction{
  font-size:12px;
  line-height:1;
  color:rgba(255,255,255,.8) !important;
  margin-top:10px;
  font-weight:300
}

.rewards .wallet-card.activity-nft .reward-card-right button.hidden{
  display:none
}

.rewards .wallet-card.activity-nft .reward-card-right button.visible{
  display:block
}

.rewards .wallet-card.activity-nft .reward-card-right button.disabled{
  pointer-events:none;
  background:gray
}

.rewards .wallet-card.activity-nft .reward-card-right button.active{
  pointer-events:all
}

.rewards .wallet-card.activity-nft .redeem-btn{
  visibility:visible;
  display:block;
  margin:15px 0 0 0;
  height:30px;
  opacity:1;
  position:relative;
  display:block;
  width:-moz-fit-content;
  width:-webkit-fit-content;
  width:fit-content;
  background-color:#fff !important;
  padding:4px 20px !important;
  color:#000 !important
}

.rewards .wallet-card.activity-nft.locked img{
  opacity:.5
}

.rewards .wallet-card.activity-nft.locked:before{
  content:"";
  position:absolute;
  left:0;
  top:0;
  width:100%;
  height:100%;
  background:rgba(0,0,0,.2);
  opacity:1;
  z-index:1;
  pointer-events:none;
  border-radius:10px
}

.rewards .wallet-card.activity-nft.locked:after{
  content:"";
  width:50px;
  position:absolute;
  height:50px;
  opacity:.5;
  background-size:cover;
  left:22%;
  top:50%;
  transform:translate(-50%, -50%);
  z-index:1000;
  filter:invert(1);
  background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgOS43NjF2LTQuNzYxYzAtMi43NjEtMi4yMzgtNS01LTUtMi43NjMgMC01IDIuMjM5LTUgNXY0Ljc2MWMtMS44MjcgMS40NjYtMyAzLjcxNC0zIDYuMjM5IDAgNC40MTggMy41ODIgOCA4IDhzOC0zLjU4MiA4LThjMC0yLjUyNS0xLjE3My00Ljc3My0zLTYuMjM5em0tOC00Ljc2MWMwLTEuNjU0IDEuMzQ2LTMgMy0zczMgMS4zNDYgMyAzdjMuNTg3Yy0uOTI3LS4zNzYtMS45MzgtLjU4Ny0zLS41ODdzLTIuMDczLjIxMS0zIC41ODd2LTMuNTg3em0zIDE3Yy0zLjMwOSAwLTYtMi42OTEtNi02czIuNjkxLTYgNi02IDYgMi42OTEgNiA2LTIuNjkxIDYtNiA2em0yLTZjMCAxLjEwNC0uODk2IDItMiAycy0yLS44OTYtMi0yIC44OTYtMiAyLTIgMiAuODk2IDIgMnoiLz48L3N2Zz4=")
}

.rewards .no-activity{
  display:none;
  background:#fffcf4;
  border-radius:25px 25px 0px 0px;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:20px;
  line-height:24px;
  text-align:center;
  color:#0d0b1d;
  height:200px;
  width:100%
}

.rewards .popup{
  background:var(--activity_background);
  top:20%;
  position:fixed;
  height:460px;
  max-width:420px
}

.rewards .popup img{
  width:120%;
  max-width:400px;
  transform:translate(-50%);
  left:50%;
  position:relative
}

.rewards .popup .popup-inner .flip-side a[href="#/profile"]{
  text-decoration:none
}

.rewards .popup button:not(.download-btn,.show-details,.redeem-btn){
  display:none
}

.rewards .popup .download-btn,.rewards .popup .show-details{
  display:flex;
  flex-direction:row;
  justify-content:center;
  align-items:center;
  padding:12px 20px;
  height:44px;
  background:#fff;
  border-radius:20px;
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:14px;
  line-height:140%;
  color:#41295a;
  border:none;
  width:170px;
  padding:12px 16px
}

.rewards .popup .popup-text,.rewards .popup .company-details{
  color:#fff;
  font-family:"Rubik";
  font-style:normal;
  font-weight:500;
  font-size:17px;
  line-height:22px
}

.rewards .popup .popup-text #company-name,.rewards .popup .company-details #company-name{
  font-weight:bold;
  text-decoration:underline;
  text-transform:uppercase
}

.rewards .popup .emoji{
  font-size:130px;
  margin-bottom:20px;
  opacity:.8
}

@media screen and (min-width: 409px){
  .rewards .popup{
    height:500px
  }
}

@media screen and (min-width: 479px){
  .rewards .popup{
    height:600px
  }
}

@media screen and (min-width: 569px){
  .rewards .popup{
    height:700px
  }
}

.airdropsList{
  font-family:Rubik;
  padding-left:20px;
  margin-top:0;
  text-align:left;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;
  padding:10px 20px;
  margin-top:-20px
}

.airdropsList .airdrop__item{
  width:47%;
  max-width:205px;
  height:auto;
  margin-bottom:20px;
  box-shadow:inset 0px -2.75px 2.5px rgba(138,56,244,.15),inset 0px 1.5px 2.5px rgba(255,255,255,.15);
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px
}

.airdropsList .airdrop__item img{
  -webkit-backdrop-filter:blur(5.5px);
  backdrop-filter:blur(5.5px);
  border-radius:15px;
  width:100%;
  height:100%
}

.airdropsList .empty-airdrops{
  margin-top:40px;
  font-size:16px
}

.airdropsList .airdrop__item .airdrop__name{
  color:#fff;
  font-size:20px;
  margin-bottom:5px
}

.airdropsList .airdrop__item .airdrop__description{
  color:#999;
  margin-bottom:20px;
  font-size:14px
}

.airdropsList .airdrop__item .airdrop__right{
  margin-left:20px
}

.airdropsList .airdrop__item .airdrop__users .airdrop__users__title{
  margin-bottom:5px;
  font-size:larger
}

.dashboard .airdropsList{
  display:none
}

html body{
  background:var(--background);
  background-size:cover;
  background-attachment:fixed;
  background-position-x:left;
  margin:0;
  padding:0;
  overflow-x:hidden !important
}

html #root{
  overflow:hidden
}

html .mobile-wrapper{
  margin:0 auto
}

@media(min-width: 480px){
  html .mobile-wrapper{
    max-width:480px;
    overflow:hidden
  }
}

html .loader{
  background:#000;
  background-size:cover;
  z-index:9999999;
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh
}

html .loader.loaded{
  animation:loader-animation .5s forwards
}

html .loader .sp-wave{
  border-radius:50%;
  height:50px;
  position:absolute;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%);
  width:50px;
  position:relative;
  opacity:1
}

html .loader .sp-wave:before,html .loader .sp-wave:after{
  content:"";
  border:1px #fff solid;
  border-radius:50%;
  width:100%;
  height:100%;
  position:absolute;
  left:0px
}

html .loader .sp-wave:before{
  transform:scale(1, 1);
  opacity:1;
  animation:spWaveBe .6s infinite linear
}

html .loader .sp-wave:after{
  transform:scale(0, 0);
  opacity:0;
  animation:spWaveAf .6s infinite linear
}

@keyframes spWaveAf{
  from{
    transform:scale(0.5, 0.5);
    opacity:0
  }

  to{
    transform:scale(1, 1);
    opacity:1
  }
}

@keyframes spWaveBe{
  from{
    -webkit-transform:scale(1, 1);
    opacity:1
  }

  to{
    -webkit-transform:scale(1.5, 1.5);
    opacity:0
  }
}

html .loader .lagoon-loader-logo{
  position:absolute;
  left:50%;
  height:200px;
  top:40%;
  transform:translate(-50%, -50%);
  animation:logo-animation 5s forwards
}

@keyframes loader-animation{
  0%{
    visibility:visible;
    opacity:1;
    z-index:99999999;
    background-position:10% 0%
  }

  80%{
    opacity:1
  }

  100%{
    opacity:0;
    z-index:-1;
    background-position:28% 0%
  }
}

@keyframes logo-animation{
  0%{
    transform:translateX(-100%)
  }

  100%{
    transform:translateX(100%)
  }
}

.ar{
  height:100vh
}

.ar header{
  left:0px;
  top:0px;
  z-index:100;
  position:relative;
  padding:16px 24px 16px 0;
  background-color:rgba(0,0,0,.3);
  width:100%;
  z-index:1000;
  position:fixed
}

@media screen and (min-width: 544px){
  .ar header{
    max-width:480px;
    left:50%;
    transform:translateX(-50%);
    padding-left:0;
    padding-right:0
  }
}

.ar header .top{
  font-family:Rubik;
  font-style:normal;
  font-weight:500;
  font-size:24px;
  line-height:150%;
  display:flex;
  align-items:center;
  justify-content:center;
  color:#fff
}

.ar header .top a{
  left:24px;
  display:flex;
  align-self:center;
  position:absolute
}

.ar .ar-wrapper{
  height:100vh;
  width:100vw;
  max-width:480px;
  background:#000
}

.ar .ar-wrapper .ar-scene-wrap{
  height:280px;
  width:280px;
  border-radius:50%;
  position:relative;
  overflow:hidden !important;
  left:50%;
  top:40%;
  transform:translate(-50%, -50%)
}

.ar .ar-wrapper .ar-scene-wrap video{
  display:none !important;
  background-color:#000;
  left:50% !important;
  top:40% !important;
  position:absolute;
  animation:the-end 8s;
  animation-fill-mode:forwards;
  transform:translate(-50%, -50%)
}

.ar .ar-wrapper .face-id-overlay{
  left:50%;
  top:40%;
  z-index:10;
  position:absolute;
  transform:translate(-50%, -50%)
}

.ar .ar-wrapper .face-id-overlay .finalized{
  background-color:var(--buttons_background);
  opacity:0;
  width:290px;
  height:290px;
  position:absolute;
  z-index:-1;
  top:50%;
  left:50%;
  transform:translate(-50%, -50%);
  border-radius:50%;
  animation:the-finalized 8.5s;
  animation-fill-mode:forwards;
  border:20px solid rgba(255,255,255,.1)
}

.ar .ar-wrapper .face-id-overlay .button{
  z-index:1000;
  display:block;
  line-height:70px;
  font-size:2em;
  position:absolute;
  font-family:"Rubik";
  text-align:center;
  border-radius:50%;
  bottom:7px;
  opacity:0;
  width:330px;
  line-height:450px;
  height:330px;
  color:#fff;
  background:transparent;
  left:50%;
  transform:translateX(-50%);
  transform-origin:center center;
  box-shadow:transparent;
  transition:.2s all
}

.ar .ar-wrapper .face-id-overlay .button.ready{
  animation:opacity0100 1s forwards
}

.ar .ar-wrapper .face-id-overlay svg{
  height:340px !important;
  width:340px !important
}

.ar .ar-wrapper .face-id-overlay svg #loading line{
  animation:the-end 8.5s;
  animation-fill-mode:forwards
}

.ar .ar-wrapper .face-id-overlay svg #face{
  transform-origin:center
}

.ar .ar-wrapper .face-id-overlay svg #face *{
  stroke:#fff
}

.ar .ar-wrapper .face-id-overlay #face-id-set-up{
  animation-fill-mode:forwards
}

.ar .ar-wrapper .face-id-overlay #face{
  animation:face-move 6.8s .5s;
  transform-origin:center center
}

.ar .ar-wrapper .face-id-overlay #eyes{
  animation:eyes-blink 6s 1.5s infinite;
  transform-origin:center center
}

.ar .ar-wrapper .face-id-overlay #nose{
  animation:nose-move 7s .5s;
  transform-origin:15% 15% .5%
}

.ar .ar-wrapper .face-id-overlay #mouth{
  animation:mouth-move 7s .5s
}

@keyframes face-move{
  15%{
    transform:rotateY(25deg)
  }

  30%{
    transform:rotateY(25deg) rotateX(25deg)
  }

  55%{
    transform:rotateY(-25deg) rotateX(25deg)
  }

  75%{
    transform:rotateY(-25deg) rotateX(-25deg)
  }

  100%{
    transform:rotateY(25deg) rotateX(-25deg)
  }
}

@keyframes nose-move{
  15%{
    transform:rotateY(15deg) translateX(6px)
  }

  30%{
    transform:rotateY(15deg) rotateX(15deg) translateX(1px)
  }

  55%{
    transform:rotateY(-15deg) rotateX(15deg)
  }

  75%{
    transform:rotateY(-15deg) rotateX(5deg) translateY(4px)
  }

  100%{
    transform:rotateY(10deg) rotateX(10deg) translateX(2px) translateY(5px)
  }
}

@keyframes mouth-move{
  15%{
    transform:rotateY(10deg)
  }

  30%{
    transform:rotateY(10deg) rotateX(10deg)
  }

  55%{
    transform:rotateY(-10deg) rotateX(10deg)
  }

  75%{
    transform:rotateY(-10deg) rotateX(5deg) translateX(2px) translateY(2px)
  }

  100%{
    transform:rotateY(10deg) rotateX(10deg) translateX(-3px) translateY(3px)
  }
}

@keyframes eyes-blink{
  5%{
    transform:scaleY(0.05)
  }

  6.5%{
    transform:scaleY(1)
  }
}

@keyframes the-end{
  95%{
    opacity:1
  }

  100%{
    opacity:0
  }
}

@keyframes the-finalized{
  95%{
    opacity:0
  }

  100%{
    opacity:1
  }
}

.ar .mindar-ui-loading .loader{
  border:16px solid #222;
  border-top:16px solid #fff;
  opacity:1;
  top:50%;
  transform:translate(-50%, -50%);
  left:50%
}

.ar .generating-text{
  color:#fff;
  z-index:10000;
  font-family:"Poppins";
  padding:20px 0;
  position:absolute;
  width:100%;
  bottom:25%;
  font-size:20px;
  max-width:480px
}

.ar .generating-text.expanded{
  animation:1s scale-down forwards
}

.ar .visible.popup{
  height:560px;
  z-index:10001;
  max-width:420px;
  top:10%
}

.ar .visible.popup img{
  max-width:280px;
  width:100%
}

.ar .visible.popup .popup .popup-inner{
  width:80%
}

.ar .visible.popup button{
  margin-top:20px
}

.ar .button{
  z-index:10000;
  display:block;
  line-height:70px;
  font-size:2em;
  position:fixed;
  font-family:"Rubik";
  text-align:center;
  border-radius:25px;
  bottom:100px;
  width:200px;
  height:70px;
  color:#fff;
  background:#0d0b1d url(/static/media/bg-overlay.f4489852.png);
  left:50%;
  transform:scale(0) translateX(-50%);
  box-shadow:0px 8px 36px var(--buttons_background);
  transition:.2s all
}

.ar .button:hover{
  background:#1c163e
}

.ar .button.ready{
  animation:grow 1s forwards
}

.ar img.bg{
  height:100vh;
  width:auto;
  position:absolute;
  z-index:0;
  left:0;
  top:0
}

.ar .bg{
  background-color:#f48fb1;
  height:100vh;
  width:100vw;
  position:absolute;
  z-index:0;
  left:0;
  top:0;
  animation:20s color-change infinite
}

.ar .circle{
  position:absolute;
  z-index:0;
  width:150vw;
  height:auto;
  left:50%;
  top:50%;
  transform:translate(-50%, -50%)
}

#aryel{
  background:#000
}

#aryel .aryel{
  width:100%;
  height:100vh;
  border:0 !important
}

#aryel .visible.popup{
  height:450px;
  max-width:420px;
  z-index:10001;
  top:20%
}

#aryel .visible.popup img{
  max-width:280px;
  width:100%
}

#aryel .visible.popup .popup .popup-inner{
  width:80%
}

#aryel .visible.popup button{
  margin-top:20px
}

#aryel .button{
  z-index:10000;
  display:block;
  line-height:70px;
  font-size:2em;
  position:fixed;
  font-family:"Rubik";
  text-align:center;
  border-radius:25px;
  bottom:100px;
  width:200px;
  height:70px;
  color:#fff;
  background:#0d0b1d url(/static/media/bg-overlay.f4489852.png);
  left:50%;
  transform:scale(0) translateX(-50%);
  box-shadow:0px 8px 36px rgba(84,29,148,.5);
  transition:.2s all
}

#aryel .button:hover{
  background:#1c163e
}

#aryel .button.ready{
  animation:grow 1s forwards
}

#aryel #request{
  position:absolute;
  visibility:hidden;
  bottom:20px;
  left:50%;
  transform:translateX(-50%)
}

@keyframes grow{
  0%{
    transform:scale(0) translateX(-50%)
  }

  100%{
    transform:scale(1) translateX(-50%)
  }
}

@keyframes opacity0100{
  0%{
    opacity:0
  }

  100%{
    opacity:1
  }
}

@keyframes scale-down{
  0%{
    height:70px
  }

  100%{
    height:100vh
  }
}

@keyframes color-change{
  0%{
    background-color:#f48fb1
  }

  10%{
    background-color:#880e4f
  }

  20%{
    background-color:#29b6f6
  }

  30%{
    background-color:#00e5ff
  }

  40%{
    background-color:#ab47bc
  }

  50%{
    background-color:#66bb6a
  }

  60%{
    background-color:#b2ff59
  }

  70%{
    background-color:#fdd835
  }

  80%{
    background-color:#304ffe
  }

  90%{
    background-color:#ff6f00
  }

  100%{
    background-color:#ff8a65
  }
}

/* purgecss end ignore */
/* purgecss start ignore */

/* .modalBg {
  width: 100vw;
  height: 100vh;
  background-color: rgba(200, 200, 200);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.modalContainer {
  width: 500px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.modalContainer .body {
  flex: 50% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.modalContainer .footer {
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* purgecss end ignore */

