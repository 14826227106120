footer {
	@include footer;
	z-index: 100;
	.cameraInput {
		visibility: hidden;
		#video {
			height: 80%;
		}
	}
	li.qr-around {
		width: 94px;
		height: 84px;
		background: var(--footer_menu_background);//$footer-bkg-around;
		border-radius: 50%;
		-webkit-backdrop-filter: blur(5.5px);
		backdrop-filter: blur(5.5px);
        
        svg {
            border-radius: 50%;
            background: var(--buttons_background) linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%); 
            path {
                fill: var(--buttons_color);
            }
        }
	}
	#menu-qr {
		position: absolute;
		transform-origin: center;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -80%);
	}
}
