.profile-page {
	background: var(--rewards_and_wallet_background);//$black-2 url("../assets/bg-overlay.png");
	background-blend-mode: color;
	background-size: cover;
	background-attachment: fixed;
	background-position-x: left;
	height: calc(100vh - 120px);
	padding: 1px 0 10px;
	margin-bottom: 180px;
	height: 100vh;
	overflow: hidden;
	overflow-y: scroll;
	position: fixed;
	width: 100%;
	max-width: $desktop-width;

	header {
		@include header-leaderboard;
		// padding-bottom: 0;
		position: fixed;
		z-index: 10000;
		max-width: $desktop-width;
		margin: 0 auto;
		.top {
			p {
				margin: 0;
			}
			button {
				background: none;
				border: none;
				position: fixed;
				right: 24px;
				top: 24px;
				svg {
					path {
						stroke: var(--buttons_background);
					}
				}
			}
		}

		&.header-greetings {
			background: transparent;
			position: relative;
			margin-top: 80px;
			z-index: 1;
		}
	}
	.profile {

		.profile-wrapper {
			// background: #fffcf4;
			padding: 18px 0;
			border-radius: 25px 25px 0px 0px;

			.dash {
				border: 1px solid rgba(0, 0, 0, 0.15);
				width: 26px;
				margin: 0 auto;
			}
		}
		
		// p.profile-titles {
		// 	font-size: 34px;
		// 	// color: #000;
		// }
		
		.profile-wrapper p.profile-titles {
			font-size: 32px;
		}
		.ranks {
			display: flex;
			margin-bottom: 30px;
			// .logo {
			// 	display: none;
			// }
			// .inside-ranks {
			// 	display: flex;
			// 	width: 100%;
			// 	justify-content: center;
			// 	flex-direction: column;
			// 	.value {
			// 		margin: 0 auto;
			// 	}
			// }
			// #points {
			// 	.stars {
			// 		display: none;
			// 	}
			// }
		}
		.badge {
			pointer-events: none;
			width: 50%;
			opacity: 1;
			&.hidden-in-wallet {
				display: none;
			}
			img {
				max-width: 120px;
			}
			pointer-events: none;
		}
	}

	.logo-wrap,
	.home-titles,
	.progressVisualFull, .progressVisualRewards {
		display: none;
	}
	.header-greetings {
		// margin-top: 40px;
		height: 60px;
	}
	.ranks {
		// margin-top: 240px;
	}

	.links {
		margin: 35px 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		a {
			padding: 5px;
			@include links;
		}
		a:visited {
			color: $link-color;
		}
	}
	.popup-inner {
		.wallet-card {
			margin: 0 auto 20px;
			width: 100%;
			padding: 0 0;
		}
	}

	.card-three {
		background: $white;
		border-radius: 32px;
		margin: 0 0 30px;
		padding: 24px;
		@media screen and (min-width: $large) {
			padding-bottom: 60px;
		}

		.top-three {
			display: flex;
			justify-content: space-between;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 20px;
			line-height: 140%;
			color: $black-1;
			a {
				font-size: 14px;
				color: $dark-purple;
				text-decoration: none;
			}
		}
		.quiz-card {
			border: 2px solid $grey-5;
			box-sizing: border-box;
			margin: 16px 0px;
			background: $white;
			border-radius: 20px;
			display: flex;
			justify-content: start;
			align-items: center;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 150%;
			padding: 8px 28px 8px 8px;
			a {
				display: flex;
				align-items: center;
				width: 100%;
				text-decoration: none;
			}
			.left {
				margin: 6px 16px 0 0;
			}
			.middle {
				.quiz-name {
					margin: 0;
					color: $black-1;
					text-decoration: none;
				}
				.quiz-description {
					font-weight: normal;
					font-size: 12px;
					color: $grey-2;
					margin: 6px 0px;
				}
			}
			.right {
				margin-left: auto;
			}
		}
	}
	.logout-btn {
		color: #fff;
		text-align: center;
		position: relative;
		bottom: 200px;
		font-family: 'Rubik';
		font-size: 1.1em;
	}
	// button.logout-btn {
	// 	margin-top: 100px;
	// 	width: 40%;
	// 	flex-direction: row;
	// 	justify-content: center;
	// 	align-items: center;
	// 	padding: 12px 20px;
	// 	height: 44px;
	// 	background: $white;
	// 	border-radius: 20px;
	// 	font-family: Rubik;
	// 	font-style: normal;
	// 	font-weight: 500;
	// 	font-size: 14px;
	// 	line-height: 140%;
	// 	color: $dark-purple;
	// 	border: none;
	// 	padding: 12px 16px;
	// }
	footer {
		li:not(:last-child) {
			opacity: 0.3;
		}
	}
}
