.packets {
	margin-top: 30px;
	position: relative;
	top: 50px;
	header {
		@include header-leaderboard;
		position: fixed;
	}
	button {
		// margin-bottom: 60px;
		visibility: hidden;
		@include button;
		margin-bottom: 160px;
	}

	.activities {
		.wallet {
			display: none;
		}
	}

	.no-activity {
		display: none;
		background: $module-background;
		border-radius: 25px 25px 0px 0px;
		// bottom: 30px;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: $black-2;
		height: 200px;
		width: 100%;
	}
}
