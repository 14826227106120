.progress-bar {
	height: 25px;
	width: 93%;
	border-radius: 50px;
	margin: 15px auto;
	display: flex;
	background-color: #e0e0de;
	.filler {
		height: 100%;
		width: 50%;
		background-color: rgb(181 150 231);
		transition: width 1s ease-in-out;
		border-radius: inherit;
		text-align: center;
		display: flex;
		justify-content: space-between;
	}
}
