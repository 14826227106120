.downloader {
	height: 100%;
	header {
		@include header-leaderboard;
	}
	// background: rgba(0,0,0,0.6);
	#top-downloader {
		display: flex;
		justify-content: center;
		// padding-bottom: 158px;
		// background: conic-gradient(
		// 	from 0deg at 50% 50%,
		// 	#ffe710 0deg,
		// 	#ffbd20 360deg
		// );
		@media screen and (min-width: $medium) {
			// padding-bottom: 188px;
		}
		@media screen and (min-width: $large) {
			// padding-bottom: 220px;
		}

		.greetings {
			margin-top: 30px;
		}
		.flex {
			margin-top: 150px;
			// p {
			// 	@include names;
			// }
			.flex-col {
			}
			button {
				margin-top: 20px;
			}
		}
	}
	footer {
		li {
			opacity: 0.3;
		}
	}
}
