.memory-game {
	height: calc(100vh - 120px);
	header {
		@include header-leaderboard;
	}
	padding: 20px 10px 205px;
	padding-bottom: 0;
	padding-top: 90px;
	// background: conic-gradient(
	// 	from 0deg at 50% 50%,
	// 	#ffe710 0deg,
	// 	#ffbd20 360deg
	// );
	.card-two {
		margin: 24px;
		.mid-featured {
			margin: 10px 35px 35px;
			font-size: 15px;
		}
		.btm-featured {
			display: none;
		}
	}
	img {
		display: flex;
		max-width: 500px;
		width: 50%;
		height: auto;
		margin: auto;
	}
	.memory-foundMatch {
		@include btn-tertiary;
		display: inline-block;
		/* flex-direction: row; */
		/* justify-content: center; */
		/* align-items: center; */
		/* padding: 16px 133px; */
		width: 170px;
		padding: 0;
	}
	.timer-wrap {
		display: flex;
		justify-content: end;
		margin: 15px 36px 0 0;
		.timer {
			width: 100px;
			height: 34px;
			background: #5144b6;
			border-radius: 12px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 150%;
			color: #e6e6e6;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
		}
	}
	.popup-inner {
		border-radius: 20px;
		position: absolute;
		transform: translate(-50%, -50%);
		left: 50%;
		width: calc(100% - 68px);
		height: 155px;
		background-color: #fff;
		button {
			@include btn-primary;
			position: absolute;
			width: 130px;
			height: 35px;
			padding: 0;
			transform: translate(-50%);
			left: 50%;
		}
		h2 {
			margin-top: 34px;
			opacity: 0.7;
		}
	}
	footer {
		left: 0;
		li {
			opacity: 0.3;
		}
	}
}
