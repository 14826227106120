.rewards {
	background: var(--rewards_and_wallet_background);//$black-2 url("../assets/bg-overlay.png");
	background-blend-mode: color;
	background-size: cover;
	background-attachment: fixed;
	background-position-x: left;
	height: calc(100vh - 120px);
	padding: 1px 0 10px;
	margin-bottom: 180px;
	height: 100vh;
	overflow: hidden;
	overflow-y: scroll;
	position: fixed;
	width: 100%;
	max-width: $desktop-width;

	.profile-wrapper {
		// background: #fffcf4;
		padding: 0 0;
		border-radius: 25px 25px 0px 0px;
		margin-top: 68px;

		.rewards-header {
			align-items: center;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			padding: 24px 0;
			font-size: 19px;
			line-height: 24px;
			background: var(--rewards_header_background);//url('../assets/bg-overlay.png');
			background-size: cover;
			background-attachment: fixed;
			background-position-x: left;

			.rewards-header-flex {
				display: flex;
				justify-content: space-between;

				.avatar {
					border-radius: 50%;
					margin: 0 12px 0 20px;
					width: 44px;
				}

				img {
					border-radius: 50%;
					top: 6%;
					left: 6%;
					height: 88%;
					width: 88%;
				}

				.points-box {
					width: 200px;
					position: relative;
					text-align: left;
					background: rgba(0, 0, 0, 0.6);
					box-shadow: 2px 2px 8px 15px rgb(0 0 255 / 4%);
					border-radius: 10px;
					padding: 10px 30px;
					margin-right: 24px;

					.points-centered {
						position: absolute;
						top: 50%;
						transform: translateY(-50%);
					}

					p.points-text {
						color: #fff;
						font-size: 32px;
						/* margin: 0; */
						margin: 0 0 0 0;
						/* margin-top: 25px; */
					}

					p.points-caption {
						color: #ccc;
						font-size: 14px;
						margin: 0;
						font-weight: 300;
						margin-top: 5px;
					}
				}
				svg {
					circle:nth-child(1) {
						stroke: var(--activity_background);
					}
					circle:nth-child(2) {
						stroke: var(--greeting_text_color);
					}
				}
			}

		}

		.rewards-body {
			padding: 40px 24px;
			padding-bottom: 250px;
		}

		.dash {
			border: 1px solid rgba(0, 0, 0, 0.15);
			width: 26px;
			margin: 0 auto;
		}

		p.profile-titles {
			font-size: 32px;
			margin: 0;
			align-items: center;
			text-align: left;
			padding-left: 0;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			color: $white;

			&.mb-11 {
				margin-bottom: 11px;
			}
		}
	}

	.reward {
		margin-top: 20px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	// top: 50px;
	header {
		@include header-leaderboard;
		position: fixed;
	}

	button:not(.redeem-btn) {
		// margin-bottom: 60px;
		visibility: hidden;
		@include button;
		margin-bottom: 160px;
	}

	.btn-redeem {
		display: block;
	}

	.activities {
		.wallet {
			display: none;
		}
	}

	.wallet-card {
		&.activity-nft {
			width: 100%;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			position: relative;
			line-height: 19px;
			// max-width: 205px;
			height: auto;
			color: $white;
			margin-bottom: 20px;
			/*background: linear-gradient(95.46deg, #1c163e 41.4%, #171032 60.9%);*/
			background: var(--activity_background) !important;
			box-shadow: inset 0px -2.75px 2.5px rgb(138 56 244 / 15%), inset 0px 1.5px 2.5px rgb(255 255 255 / 15%);
			-webkit-backdrop-filter: blur(5.5px);
			backdrop-filter: blur(5.5px);
			border-radius: 15px;
			padding: 20px 30px;
			// margin: 0 auto;
			display: flex;

			.emoji {
				margin-top: 0;
			}

			&::after {
				opacity: 0;
			}

			p {
				margin-bottom: 0;
			}

			img {
				width: auto;
				height: 90px;
			}

			.reward-card-right {
				text-align: left;
				padding-left: 20px;
				align-self: center;

				p:first-of-type {
					margin-top: 0;
					font-size: 1.2em;
				}

				.price-in-points {
					font-size: 14px;
					font-weight: 500 !important;
					color: rgba(255,255,255,0.8) !important;
					margin: 0;
					font-weight: 300;
					margin-top: 5px;
				}

				.instruction {
					font-size: 12px;
					line-height: 1;
					color: rgba(255,255,255,0.8) !important;
					margin-top: 10px;
					font-weight: 300;
				}

				button {
					&.hidden {
						display: none;
					}
					&.visible {
						display: block;
					}
					&.disabled {
						pointer-events: none;
						background: gray;
					}
					&.active {
						pointer-events: all;
					}
				}
			}

			.redeem-btn {
				visibility: visible;
				display: block;
				margin: 15px 0 0 0;
				height: 30px;
				opacity: 1;
				position: relative;
				display: block;
				width: -moz-fit-content;
				width: fit-content;
				// background: blue;
				// background: linear-gradient(180deg, #e337ff 0%, #882aff 100%);
				background-color: #fff !important;
				padding: 4px 20px !important;
				color: #000 !important;
			}

			&.locked {
				img {
					opacity: 0.5;
				}
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: rgba(0,0,0,0.2);
					opacity: 1;
					z-index: 1;
					pointer-events: none;
					border-radius: 10px;
				}
				&:after {
					content: '';
					width: 50px;
					position: absolute;
					height: 50px;
					opacity: 0.5;
					background-size: cover;
					left: 22%;
					top: 50%;
					transform: translate(-50%, -50%);
					z-index: 1000;
					filter: invert(1);
					background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgOS43NjF2LTQuNzYxYzAtMi43NjEtMi4yMzgtNS01LTUtMi43NjMgMC01IDIuMjM5LTUgNXY0Ljc2MWMtMS44MjcgMS40NjYtMyAzLjcxNC0zIDYuMjM5IDAgNC40MTggMy41ODIgOCA4IDhzOC0zLjU4MiA4LThjMC0yLjUyNS0xLjE3My00Ljc3My0zLTYuMjM5em0tOC00Ljc2MWMwLTEuNjU0IDEuMzQ2LTMgMy0zczMgMS4zNDYgMyAzdjMuNTg3Yy0uOTI3LS4zNzYtMS45MzgtLjU4Ny0zLS41ODdzLTIuMDczLjIxMS0zIC41ODd2LTMuNTg3em0zIDE3Yy0zLjMwOSAwLTYtMi42OTEtNi02czIuNjkxLTYgNi02IDYgMi42OTEgNiA2LTIuNjkxIDYtNiA2em0yLTZjMCAxLjEwNC0uODk2IDItMiAycy0yLS44OTYtMi0yIC44OTYtMiAyLTIgMiAuODk2IDIgMnoiLz48L3N2Zz4=");
				}
			}
		}
	}

	.no-activity {
		display: none;
		background: $module-background;
		border-radius: 25px 25px 0px 0px;
		// bottom: 30px;
		font-family: "Rubik";
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		color: $black-2;
		height: 200px;
		width: 100%;
	}

	.popup {
		// background: linear-gradient(
		// 	114.2deg,
		// 	rgba(117, 73, 174, 0.9) 15.5%,
		// 	rgba(93, 37, 166, 1) 93.71%
		// );
		// background: linear-gradient(95.46deg,
		// 		$footer-first 41.4%,
		// 		$footer-second 60.9%);
		background: var(--activity_background);
		top: 20%;
		position: fixed;
		height: 460px;
		max-width: $desktop-modal-width;

		img {
			width: 120%;
			max-width: 400px;
			transform: translate(-50%);
			left: 50%;
			position: relative;
		}

		.popup-inner {
			.flip-side {
				a[href="#/profile"] {
					text-decoration: none;
				}
			}
		}

		button:not(.download-btn, .show-details, .redeem-btn) {
			display: none;
		}

		.download-btn,
		.show-details {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 12px 20px;
			height: 44px;
			background: $white;
			border-radius: 20px;
			font-family: Rubik;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 140%;
			color: $dark-purple;
			border: none;
			width: 170px;
			padding: 12px 16px;
		}

		.popup-text,
		.company-details {
			color: $white;
			font-family: "Rubik";
			font-style: normal;
			font-weight: 500;
			font-size: 17px;
			line-height: 22px;

			#company-name {
				font-weight: bold;
				text-decoration: underline;
				text-transform: uppercase;
			}
		}

		.emoji {
			font-size: 130px;
			margin-bottom: 20px;
			opacity: 0.8;
		}

		@media screen and (min-width: 409px) {
			height: 500px;
		}

		@media screen and (min-width: 479px) {
			height: 600px;
		}

		@media screen and (min-width: 569px) {
			height: 700px;
		}
	}
}
